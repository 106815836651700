import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import BackendServiceV2 from 'services/v2/BackendService';
import FileUploader from '../../../components/FileUploader';
import QuoteForm from '../../../components/RealTimeQuoteForm';
import SectionBox from '../../../components/SectionBox';

function PricingEdit() {

    const params = useParams();

    const [pricingRequest, setPricingRequest] = useState({});

    const getPricingRequest = useCallback(async() => {
        let response = await BackendServiceV2.get('/pricing/request/' + params.idPricing);

        if(response.status === 200)
            setPricingRequest(response.data)
    }, [params.idPricing]);


    const [pricingRequestTimeline, setPricingRequestFiles] = useState([]);
    const getPricingRequestFiles = useCallback(async () => {
        let response = await BackendServiceV2.get('/pricing/request/' + params.idPricing + '/file');

        if(response.status === 200)
            setPricingRequestFiles(response.data.entries)
    }, [params.idPricing]);

    useEffect(() => {
        getPricingRequest();
        getPricingRequestFiles();
    // eslint-disable-next-line
    }, []);

    // FILE UPLOAD
    const fileUploadRef = useRef(undefined);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [uploadFilesProgress, setUploadFilesProgress] = useState([]);
    const [totalUploadProgress, setTotalUploadProgress] = useState(undefined);

    const { BlobServiceClient } = require("@azure/storage-blob");

    async function handleFileUpload(e) {
        e.preventDefault();
        setUploadFiles(Array.from(fileUploadRef.current.files));
    }

    useEffect(() => {
        if(uploadFiles && uploadFiles.length > 0) {
            let totalUploads = 0;
            setTotalUploadProgress(0);
            
            uploadFiles.forEach(async (file) => {
                try {
                    let uploadURLresponse = await BackendServiceV2.put(`/pricing/request/${pricingRequest.id}/file/upload`, {
                        filename: file.name
                    });

                    if(uploadURLresponse && uploadURLresponse.status === 200) {
                        const blobServiceClient = new BlobServiceClient(window.ENV.StaticURL + uploadURLresponse.data.signedURL);
                        
                        const containerClient = blobServiceClient.getContainerClient('main-storage');
                        const blockBlobClient = containerClient.getBlockBlobClient(uploadURLresponse.data.entityDir + uploadURLresponse.data.filename);

                        await blockBlobClient.uploadData(file, {
                            onProgress: (progressEvent) => {
                                setUploadFilesProgress(oldProgress => {
                                    return {...oldProgress, [file.name] : Math.round((progressEvent.loadedBytes * 100) / file.size) + '%'}
                                });
                            }
                        });
                    }

                    totalUploads++;
                } catch (error) {
                    totalUploads++;
                    setUploadFilesProgress(oldProgress => {
                        return {...oldProgress, [file.name] : error.response.data.message}
                    });
                }

                if(totalUploads >= uploadFiles.length) {
                    getPricingRequestFiles();
                    setTotalUploadProgress(100);
                }
            });
        }
    // eslint-disable-next-line
    }, [uploadFiles]);

    return (
        <div className="main-content">
            <div className="left-content">
                <QuoteForm pricingRequestDB={pricingRequest} />
            </div>  

            <div className="right-content">
                {pricingRequest && pricingRequest.status === 'APPROVED' &&
                    <SectionBox
                        title="File upload">

                        {pricingRequestTimeline && pricingRequestTimeline.map(timelineEntry => (
                            <div key={timelineEntry.name} className="timeline-entry">
                                {timelineEntry.type === 'FILE' && <>
                                    <b><a href={timelineEntry.path} target="_blank" rel="noopener noreferrer">{timelineEntry.name}</a></b>
                                    <div className="timeline-createdAt">Enviado: {timelineEntry.createdAt}</div>
                                </>}
                            </div>
                        ))}

                        <form onSubmit={handleFileUpload}>
                            <input className="file-upload-input" type="file" name="file" ref={fileUploadRef} required multiple />
                            <FileUploader hiddenInput={fileUploadRef} progress={totalUploadProgress} autoSubmit={true} />
                        </form>

                        {uploadFiles && uploadFiles.length > 0 && <>
                            <ul style={{'marginTop' : '15px'}}>
                                {uploadFiles && uploadFiles.map(file => (
                                    <li key={file.name} className="timeline-entry">{uploadFilesProgress[file.name]} - {file.name}</li>
                                ))}
                            </ul>
                        </>}
                    </SectionBox>
                }
            </div>           
        </div>
    )
}

export default PricingEdit;