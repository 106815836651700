import { ActionIcon, Badge, Group, Menu, Stack, Text } from "@mantine/core";
import React from "react";
import { TotemPageDeleteButton } from "../TotemPageDeleteButton";
import { TotemContentPreview } from "../TotemPagePreview";
import { useSortable } from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';
import { DotsVertical, GripVertical, Home } from "tabler-icons-react";
import { TotemPageSetHome } from "../TotemPageSetHome";

export function TotemPageCard({page, totem}) {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: page.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <Stack key={page.id} style={{...style, width: '100%'}} ref={setNodeRef} {...attributes}>
            <Group noWrap position="apart">
                <Group noWrap>
                    <Group spacing={0} noWrap>
                        <GripVertical {...listeners} style={{cursor: 'grab'}} color="gray"/>
                        
                        <Text weight={600} lineClamp={1}>{page.name}</Text>
                    </Group>
                    <Badge variant='outline'>{page.id}</Badge>
                    
                </Group>
                <Group noWrap>
                    {page.home && <Home color="#ff5e00" size={22} />}
                    <Menu>
                        <Menu.Target>
                            <ActionIcon><DotsVertical /></ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                            <TotemPageSetHome page={page} />
                            <TotemPageDeleteButton page={page} />
                        </Menu.Dropdown>
                    </Menu>
                    
                </Group>
            </Group>
            <Group spacing={0} grow className='totem-screen-wrapper' noWrap>
                {page?.contents?.filter(content => content.position === 'LEFT').map(item => (
                    <TotemContentPreview  key={item.id} label={item.position} content={item} pages={totem?.pages} type={page?.contents?.length === 1 ? 'SINGLE' : 'MULTI'} showcaseType={totem.type} currentPage={page} />
                ))}
                {page?.contents?.filter(content => content.position === 'MAIN').map(item => (
                    <TotemContentPreview key={item.id} label={item.position} content={item} pages={totem?.pages} type={page?.contents?.length === 1 ? 'SINGLE' : 'MULTI'} showcaseType={totem.type} currentPage={page} />
                ))}
                {page?.contents?.filter(content => content.position === 'RIGHT').map(item => (
                    <TotemContentPreview key={item.id} label={item.position} content={item} pages={totem?.pages} type={page?.contents?.length === 1 ? 'SINGLE' : 'MULTI'} showcaseType={totem.type} currentPage={page} />
                ))}
            </Group>
        </Stack>
    )
}