import React, { useCallback, useEffect, useState } from 'react';
import Select from '../form/Select';
import Separator from '../form/Separator';
import Tooltip from '../form/Tooltip';
import SectionBox from '../SectionBox';
import BackendService from '../../services/BackendService';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
//import Message from '../Message';
import ServiceInfo from './ServiceInfo';
import ServiceAmbientInfo from './ServiceAmbientInfo';

function RealTimeQuoteForm({pricingRequestDB}) {

    // PRICING REQUEST
    const[pricingRequest, setPricingRequest] = useState(undefined);
    
    useEffect(() => {
        setPricingRequest(pricingRequestDB);
    }, [pricingRequestDB]);

    // READ ONLY PARAMETER
    const[isReadOnly, setIsReadOnly] = useState(false);

    // ACCOUNT
    const [me, setMe] = useState({});

    // PERMISSIONS
    const [ADMIN_ROLE, setAdminRole] = useState(false);

    useEffect(() => {
        async function getPermissions() {
            setAdminRole(await UserService.hasRole('ADMIN'));
        }

        setMe(JSON.parse(localStorage.getItem('me')));

        getPermissions();
    }, []);

    useEffect(() => {
        if(ADMIN_ROLE) {
            setIsReadOnly(false);
            return;
        }

        if(pricingRequest && !ADMIN_ROLE) {
            if (pricingRequest.status === 'DRAFT' || pricingRequest.status === 'USER_VALIDATION') {
                setIsReadOnly(false);
            } else {
                setIsReadOnly(true);
            }
        } else {
            setIsReadOnly(true);
        }
    }, [pricingRequest, ADMIN_ROLE]);

    // NOTIFICATION
    /*const [resultMessage, setResultMessage] = useState(undefined);
    const [typeMessage, setTypeMessage] = useState(undefined);

    function showMessage(message, isError) {
        setResultMessage(message);
        isError ? setTypeMessage('error') : setTypeMessage('success');
    }

    function cleanMessage() {
        setResultMessage(undefined);
        setTypeMessage(undefined);
    }*/

    // SAVING STATUS
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if(!isSaving)
            getSummary();
    // eslint-disable-next-line
    }, [isSaving]);

    // BASE
    const [baseData, setBaseData] = useState({});

    // TYPE
    const [typeData, setTypeData] = useState({});
    const [typeOptions] = useState([
        {'id': 'TELEPORT_APARTMENT','value': 'Apartamento/Casa'},
        {'id': 'TELEPORT_COMMON_AREA','value': 'Área Comum'}
    ]);

    async function handleTypeChange(value) {
        if(!baseData.user || !baseData.user.id) {
            baseData['user'] = {
                id: me.id,
                value: me.email
            }
        }

        let id = undefined;
        if(pricingRequest) {
            id = pricingRequest.id;
        }

        let response = await BackendService.put('/pricing/request', {
            id: id,
            base: baseData, 
            type: value
        });

        if(response && response.status >= 201) {
            setTypeData({...typeData, type: value});

            if(window.location.pathname !== '/pricing/' + response.data.id) {
                window.location = '/pricing/' + response.data.id
            } else {
                setPricingRequest(response.data);
            }
        }
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if(baseData.additionalObservation && baseData.additionalObservation !== pricingRequest.base.additionalObservation) {
                handleTypeChange(typeData.type);
            }
        }, 3000)
    
        return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
    }, [baseData.additionalObservation]);

    // TOUR
    const [selectedTour, setSelectedTour] = useState({});

    async function handleAmbientAdd(e) {
        e.preventDefault();

        let response = await BackendService.put('pricing/request/' + pricingRequest.id + '/ambient', selectedTour.ambient)

        if(response && response.status <= 201) {
            setPricingRequest(response.data);
        }
    }

    // PROJECT
    const [projectData, setProjectData] = useState({});
    const [projectDataForms] = useState([
        {'id':'BLUEPRINT', 'label': 'Planta baixa', 'required': true, 'type': 'ALL'},
        {'id':'ELEVATIONS', 'label': 'Elevações', 'required': true, 'type': 'ALL'},
        {'id':'INTERIOR_DESIGN', 'label': 'Projeto de Interiores', 'required': true, 'tooltip' : 'Projeto estético e funcional de mobiliários, decoração e iluminação. Serviço adicionado ao orçamento, caso não marcado.', 'type': 'TELEPORT_APARTMENT'},
        {'id':'DESCRIPTIVE_MEMORIAL', 'label': 'Memorial Descritivo', 'tooltip' : 'Layout e indicações de mobiliários', 'type': 'ALL'},
        {'id':'MOODBOARD', 'label': 'Moodboard', 'type': 'ALL'},
        {'id':'FLOOR_PLAN', 'label': 'Planta de pisos', 'type': 'ALL'},
        {'id':'CEILING_PLAN', 'label': 'Planta de Forro', 'type': 'ALL'},
        {'id':'LIGHTING_PROJECT', 'label': 'Projeto Luminotécnico', 'type': 'ALL'},
        {'id':'STUDIO_MAX', 'label': 'Modelo em 3D Studio Max', 'type': 'ALL'},
        {'id':'SKETCHUP', 'label': 'Modelo em SketchUp', 'type': 'ALL'},
        {'id':'REVIT', 'label': 'Modelo em Revit', 'type': 'ALL'}
    ]);

    async function handleToggleProjectFile(e, fileType, fileData) {
        let response;
        setIsSaving(true);

        if(fileData.id) {
            response = await BackendService.delete('/pricing/request/' + pricingRequest.id + '/file/' + fileData.id);
        } else {
            response = await BackendService.put('/pricing/request/' + pricingRequest.id + '/file', {
                fileType: fileType
            });
        }

        setIsSaving(false);
        if(response && response.status <= 201) {
            setPricingRequest(response.data);
        }
    }

    // ADDITIONAL
    const [additionalData, setAdditionalData] = useState({});
    const [additionalDataForms] = useState([
        {'id':'TELEPORT_AR', 'label': 'Teleport AR', 'type': 'ALL'},
        {'id':'VR_360', 'label': 'Teleport VR 360°', 'type': 'ALL', 'tooltip': 'Plataforma de navegação em realidade virtual entre os mesmos pontos do Teleport Project'},
        {'id':'VR_3D', 'label': 'Teleport VR 3D', 'type': 'ALL', 'isAmbient': true, 'tooltip': 'Plataforma de navegação totalmente livre em realidade virtual pelo Teleport Project'},
        {'id':'PERSPECTIVE', 'label': 'Perspectiva', 'type': 'ALL', 'isAmbient': true},
        //{'id':'HUMANIZED_PLANT', 'label': 'Planta humanizada', 'type': 'ALL'},
        {'id':'FILM_BASE_SECONDS', 'label': 'Filme', 'type': 'ALL'},
        {'id':'OUTDOOR', 'label': 'Teleport Outdoor', 'type': 'TELEPORT_COMMON_AREA'},
        {'id':'VIDEO_EXPERIENCE', 'label': 'Video Experiência', 'type': 'TELEPORT_COMMON_AREA', 'tooltip': 'Experiência em vídeo de 1 minuto do Teleport, feito a partir de gravação de tela, com pós-produção básica inserindo logomarca, animação e música)'},
        {'id':'VARIATION_FULL', 'label': 'Variação completa de ambiente', 'type': 'TELEPORT_APARTMENT', 'isAmbient': true, 'tooltip': 'Experiência de troca completa de decoração do ambiente. Ex 1: Alterar a decoração de um cômodo entre quarto e escritório.'},
        {'id':'VARIATION_EMPTY', 'label': 'Variação de vazio e decorado', 'type': 'TELEPORT_APARTMENT'},
        {'id':'VARIATION_LOCATED', 'label': 'Variação localizada de ambiente', 'type': 'TELEPORT_APARTMENT', 'isAmbient': true, 'tooltip': 'Experiência de troca de poucos objetos em um mesmo ambiente (Ex: Móvel inteligente, cama que vira escrivaninha)'},
        {'id':'MIRROR', 'label': 'Espelhamento da planta', 'tooltip': 'Espelhamento nos eixos XY/YZ/XZ para geração de múltiplos Teleports da mesma planta', 'type': 'TELEPORT_APARTMENT'},
        {'id':'NEW_FEATURE', 'label': 'Programação de nova funcionalidade', 'type': 'ALL'}
    ]);

    // OPTIONS
    const [userOptions, setUserOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [developmentOptions, setDevelopmentOptions] = useState([]);
    const [ambientOptions, setAmbientOptions] = useState([]);

    const getUsers = useCallback(async () => {
        let response = await AuthService.get('/account/list');

        if(response.status === 200)
            setUserOptions(response.data);
    }, []);

    const getCompany = useCallback(async () => {
        let response = await BackendService.get('/company/list');

        if(response.status === 200)
            setCompanyOptions(response.data);
    }, []);

    const getDevelopment = useCallback(async (idCompany) => {
        let response = await BackendService.get('/company/' + idCompany + '/development/list');

        if(response.status === 200)
            setDevelopmentOptions(response.data);
    }, []);

    const getAmbients = useCallback(async (ambientType) => {
        let response = await BackendService.get('/pricing/' + ambientType + '/ambient/list');

        if(response.status === 200) {
            setSelectedTour({});
            setAmbientOptions(response.data);
        }
    }, []);

    useEffect(() => {
        if(ADMIN_ROLE)
            getUsers();
        getCompany();
    }, [getUsers, getAmbients, getCompany, getDevelopment, ADMIN_ROLE]);

    useEffect(() => {
        if(baseData.company !== undefined)
            getDevelopment(baseData.company.id);
    }, [baseData.company, getDevelopment])

    useEffect(() => {
        if(typeData.type !== undefined)
            getAmbients(typeData.type.id);
    }, [typeData, getAmbients]);

    // FINAL DATA
    const [smallTerms, setSmallTerms] = useState(false);

    const generateSubmitData = useCallback(async() => {
        if(baseData.user === undefined || baseData.user.id === undefined) {
            baseData['user'] = {
                id: me.id,
                value: me.email
            }
        }

        return {
            base: baseData, 
            type: {
                ...typeData.type,
                data: typeData.data
            },
            project: projectData, 
            additional: {
                ...additionalData,
            }
        }
    }, [me, baseData, typeData, projectData, additionalData])

    // CREATE PRICING REQUEST
    async function updatePricingStatus(e) {
        e.preventDefault();

        let response = await BackendService.put('/pricing/request/' + pricingRequest.id);
        
        if(response.status === 200) {
            setPricingRequest(response.data);
        }
    }

    // SUMMARY
    const [summary, setSummary] = useState([]);

    const getSummary = useCallback(async() => {
        try {
            let data =  await generateSubmitData();

            if(data.type.id === undefined)
                return
                
            let response = await BackendService.get(`/pricing/request/${pricingRequest.id}/summary`, data);
    
            if(response.status === 200)
                setSummary(response.data);
        } catch (e) {
            console.log(e);
        }
    // eslint-disable-next-line
    }, [generateSubmitData]);

    useEffect(() => {
        getSummary();
    }, [getSummary]);

    // POPULATE FORM
    useEffect(() => {
        if(pricingRequest === undefined)
            return;

        if(pricingRequest.base !== undefined)
            setBaseData(pricingRequest.base);

        if(pricingRequest.type !== undefined) {
            let type = typeOptions.find(item => item.id === pricingRequest.type.id);

            setTypeData({
                type: type,
                data: pricingRequest.data
            });
        }

        if(pricingRequest.project)
            setProjectData(pricingRequest.project);

        if(pricingRequest.additional)
            setAdditionalData(pricingRequest.additional);
    // eslint-disable-next-line
    }, [pricingRequest])

    return (
        <SectionBox
            title={
                pricingRequest ?
                'Orçamento ' + pricingRequest.id + ' - ' + pricingRequest.status :
                'Novo orçamento de Teleport'
            }>

            {
                //<Message message={resultMessage} type={typeMessage} />
            }

            {isSaving && <div className="saving-overlay" ></div>}

            <form className={isReadOnly ? 'disabled' : '' }>
                {ADMIN_ROLE &&
                    <Select
                        disabled={pricingRequest}
                        value={baseData}
                        onValueChange={value => {setBaseData({...baseData, user: value})}}
                        name="user"
                        options={userOptions}
                        label="Usuário"
                        searchable={true}
                        headerMenu={(
                            <a href="/user" target="_blank" rel="noopener noreferrer">Cadastrar novo</a>
                        )}>
                    </Select>
                }

                <div className="form-group">
                    <Select
                        disabled={pricingRequest}
                        value={baseData}
                        onValueChange={value => {setBaseData({...baseData, company: value})}}
                        name="company"
                        options={companyOptions}
                        label="Empresa"
                        searchable={true}
                        headerMenu={(
                            <a href="/company" target="_blank" rel="noopener noreferrer">Cadastrar novo</a>
                        )}>
                    </Select>
                    
                    <Select
                        disabled={pricingRequest}
                        value={baseData}
                        onValueChange={value => {setBaseData({...baseData, development: value})}}
                        name="development"
                        options={developmentOptions}
                        label="Empreendimento"
                        searchable={true}
                        headerMenu={(
                            <>{baseData.company && <a href={"/company/" + baseData.company.id} target="_blank" rel="noopener noreferrer">Cadastrar novo</a>}</>
                        )}>
                    </Select>
                </div>

                <Separator />
                
                <h1>Teleport</h1>

                <Select
                    disabled={pricingRequest}
                    value={typeData}
                    onValueChange={value => {handleTypeChange(value)}}
                    name="type"
                    options={typeOptions}
                    label="Tipo">
                </Select>

                {
                    // TODO save type data
                }

                {typeData.type && typeData.type.id === 'TELEPORT_APARTMENT' &&
                    <div className="form-group">
                        <div className="form-field">
                            <label htmlFor="tower">Torre</label>
                            <input 
                                type="text" 
                                placeholder="Única" 
                                id="tower"
                                name="tower"
                                onChange={(e) => {
                                    setTypeData({...typeData, data: {...typeData.data, [e.target.name] : e.target.value}})
                                }} />
                        </div>

                        <div className="form-field">
                            <label htmlFor="floor">Pavimento</label>
                            <input 
                                type="text" 
                                placeholder="7" 
                                id="floor"
                                name="floor"
                                onChange={(e) => {
                                    setTypeData({...typeData, data: {...typeData.data, [e.target.name] : e.target.value}})
                                }} />
                        </div>

                        <div className="form-field">
                            <label htmlFor="unity">Unidade</label>
                            <input 
                                type="text" 
                                placeholder="705" 
                                id="unity"
                                name="unity"
                                onChange={(e) => {
                                    setTypeData({...typeData, data: {...typeData.data, [e.target.name] : e.target.value}})
                                }} />
                        </div>
                    </div>
                }

                {typeData.type && <>
                    <Separator />

                    <h1>Passeio</h1>

                    {!isReadOnly && <>
                        <p>Adicione quais ambientes você deseja passear em seu Teleport</p>

                        <div className="form-group align-end">
                            <Select
                                value={selectedTour}
                                onValueChange={value => {setSelectedTour({...selectedTour, ambient: value})}}
                                name="ambient"
                                options={ambientOptions}
                                label="Tipo"
                                searchable={true}>
                            </Select>

                            <button className="select-button" onClick={handleAmbientAdd}>+</button>
                        </div>
                    </>}                    

                    {pricingRequest && pricingRequest.services && pricingRequest.services.filter(item => item.type === "BASE").map(baseService => (
                        <div key={baseService.id}>
                            {baseService.ambients.map(serviceAmbient => (
                                <ServiceAmbientInfo 
                                    key={serviceAmbient.id} 
                                    pricingRequest={pricingRequest} 
                                    baseService={baseService} 
                                    serviceAmbient={serviceAmbient}
                                    setPricingRequest={setPricingRequest}
                                    setIsSaving={setIsSaving} 
                                    isReadOnly={isReadOnly} />
                            ))}
                        </div>
                    ))}
                    
                    <Separator />

                    <h1>Projeto</h1>
                    <p>
                        Para produzir seu Teleport precisamos do máximo de informações dispóniveis. Marque os documentos de projeto que você possui. 
                        <b> Planta Baixa, Elevações</b> e <b>Projeto de Interiores</b> são obrigatórios. 
                        <b>Projeto de Interiores</b> é oferecido como um serviço adicional.
                    </p>

                    <h2>Obrigatórios</h2>

                    {pricingRequest && projectDataForms.filter(v => v.required && typeData.type && (v.type === typeData.type.id || v.type === 'ALL')).map(projectDataForm => {
                        let fileData = pricingRequest.files.find(item => item.fileType === projectDataForm.id);

                        if(!fileData)
                            fileData = {};
                        
                        return (
                            <div key={projectDataForm.id}>
                                <div className="form-field">
                                <input 
                                    type="checkbox" 
                                    id={projectDataForm.id}
                                    name={projectDataForm.id}
                                    checked={fileData.id  ? true : false}
                                    onChange={(e) => {
                                        handleToggleProjectFile(e, projectDataForm.id, fileData);
                                    }} /><label htmlFor={projectDataForm.id}>{projectDataForm.label}</label>{projectDataForm.tooltip && <Tooltip message={projectDataForm.tooltip} />}
                                </div>
                            </div>
                        )
                    })}

                    <h2>Complementares</h2>

                    {pricingRequest && projectDataForms.filter(v => !v.required && typeData.type && (v.type === typeData.type.id || v.type === 'ALL')).map(projectDataForm => {
                        let fileData = pricingRequest.files.find(item => item.fileType === projectDataForm.id);

                        if(!fileData)
                            fileData = {};
                        
                        return (
                            <div key={projectDataForm.id}>
                                <div className="form-field">
                                <input 
                                    type="checkbox" 
                                    id={projectDataForm.id}
                                    name={projectDataForm.id}
                                    checked={fileData.id  ? true : false}
                                    onChange={(e) => {
                                        handleToggleProjectFile(e, projectDataForm.id, fileData);
                                    }} /><label htmlFor={projectDataForm.id}>{projectDataForm.label}</label>{projectDataForm.tooltip && <Tooltip message={projectDataForm.tooltip} />}
                                </div>
                            </div>
                        )
                    })}

                    <Separator />

                    {
                        // TODO fix AERIAL_PHOTO service
                    }

                    <h1>Imagem aérea</h1>
                    <p>Informe como deseja que seja a vista das janelas do Teleport</p>

                    <div className="form-field">
                        <input 
                            type="radio" 
                            id="genericImage" 
                            value="IMAGE_GENERIC" 
                            name="aerialPhoto"
                            checked={additionalData["aerialPhoto"] && additionalData["aerialPhoto"].type === "IMAGE_GENERIC" ? true : false}
                            onChange={(e) => {
                                setAdditionalData({...additionalData, [e.target.name] : {type: e.target.value}})
                            }}
                        /><label htmlFor="r4">Imagem genérica</label>
                    </div>

                    <div className="form-field">
                        <input 
                            type="radio" 
                            id="360Image" 
                            value="IMAGE_HAVE" 
                            name="aerialPhoto"
                            checked={additionalData["aerialPhoto"] && additionalData["aerialPhoto"].type === "IMAGE_HAVE" ? true : false}
                            onChange={(e) => {
                                setAdditionalData({...additionalData, [e.target.name] : {type: e.target.value}})
                            }}
                        /><label htmlFor="360Image">Já possuo imagem aérea 360°</label>
                    </div>

                    <div className="form-field">
                        <input 
                            type="radio" 
                            id="360ImageHire" 
                            value="IMAGE_HIRE" 
                            name="aerialPhoto"
                            checked={additionalData["aerialPhoto"] && additionalData["aerialPhoto"].type === "IMAGE_HIRE" ? true : false}
                            onChange={(e) => {
                                setAdditionalData({...additionalData, [e.target.name] : {type: e.target.value}})
                            }}
                        /><label htmlFor="360ImageHire">Desejo contratar imagem aérea 360°</label>
                    </div>

                    {ADMIN_ROLE && pricingRequest && additionalData["aerialPhoto"] && additionalData["aerialPhoto"].type === "IMAGE_HIRE" &&
                        <div className="sub-form">
                            <div className="form-field">
                                <label htmlFor={'price-' + additionalData["aerialPhoto"].id}>Preço</label>
                                <input 
                                    type="number" 
                                    id={'price-' + additionalData["aerialPhoto"].id}
                                    name="price"
                                    value={additionalData["aerialPhoto"] && additionalData["aerialPhoto"].price}
                                    onChange={(e) => {
                                        setAdditionalData({...additionalData, "aerialPhoto" : {...additionalData["aerialPhoto"], 'price' : e.target.value}})
                                    }} 
                                />
                            </div>
                        </div>
                    }

                    <Separator />

                    <h1>Adicionais</h1>

                    {pricingRequest && additionalDataForms.filter(item => pricingRequest.type && (item.type === pricingRequest.type.id || item.type === 'ALL')).map(additionalDataForm => {                        
                        let serviceData = pricingRequest.services.find(item => item.type === additionalDataForm.id);

                        return (
                            <div key={additionalDataForm.id}>
                                <ServiceInfo 
                                    serviceDataDB={serviceData} 
                                    servicesDataForm={additionalDataForm} 
                                    idPricingRequest={pricingRequest.id} 
                                    setPricingRequest={setPricingRequest} 
                                    ambients={pricingRequest.ambients}
                                    teleportType={pricingRequest.type.id}
                                    setIsSaving={setIsSaving}
                                />
                            </div>
                        )
                    })}

                    <div className="form-field">
                        <label htmlFor="additionalObservation">Observações adicionais</label>
                        <textarea 
                            id="additionalObservation" 
                            name="additionalObservation"
                            value={ baseData['additionalObservation'] ? baseData['additionalObservation'] : ''}
                            onChange={(e) => {
                                setBaseData({...baseData, [e.target.name] : e.target.value})
                            }}></textarea>
                    </div>

                    <Separator />

                    <h1>Resumo</h1>

                    <div className="summary">
                        <ul>
                            {summary.summary && summary.summary.map(item => (
                                <li key={item.name}><span>{item.name}</span><span className="price">{item.price}</span></li>
                            ))}

                            {summary && summary.total && 
                                <li className="total"><span>{summary.total.name}</span><span className="price">{summary.total.price}</span></li>
                            }
                        </ul>
                    </div>

                    {false && ADMIN_ROLE &&
                        <div className="form-group">
                            <div className="form-field">
                                <label htmlFor="duration">Desconto</label><Tooltip message="Em %" />
                                <input 
                                    type="number" 
                                    placeholder="5" 
                                    id="duration"
                                    name="duration"
                                    //value={}
                                    //onChange={} 
                                />
                            </div>
                        </div>
                    }

                    {summary && summary.deliveryTime > 0 &&
                        <div className="deliveryTime">
                            Previsão para a primeira revisão após aprovação dos arquivos é de <b>{summary.deliveryTime} dias</b>.
                        </div>
                    }
                </>}
            </form>

            {pricingRequest && pricingRequest.status === 'DRAFT' &&
                <div className="form-group small-terms">
                    <input 
                        type="checkbox" 
                        id="terms"
                        checked={smallTerms}
                        onChange={(e) => {setSmallTerms(e.target.checked)}}
                        name="terms" /><label htmlFor="terms">Entendo que o valor exibido nessa página é uma estimativa. O valor poderá ser modificado após avaliação final e eu serei notificado para aprovar o orçamento final.</label>
                    
                    <button onClick={updatePricingStatus} disabled={!smallTerms}>Finalizar</button>
                </div>
            }

            {pricingRequest && (pricingRequest.status === 'NEW' || pricingRequest.status === 'ADMIN_VALIDATION') &&
                <div className="deliveryTime">
                    Aguardando a validação de um administrador iTeleport.
                </div>
            }

            {pricingRequest && pricingRequest.modified && pricingRequest.status !== 'DRAFT' &&
                <div className="deliveryTime">
                    O formulário foi modificado, será necessária validação da outra parte envolvida.
                </div>
            }

            {pricingRequest && (pricingRequest.status === 'NEW' || pricingRequest.status === 'ADMIN_VALIDATION') && ADMIN_ROLE && 
                <>
                    <button onClick={updatePricingStatus}>Finalizar</button>
                </>
            }

            {pricingRequest && pricingRequest.status === 'USER_VALIDATION' &&
                <>
                    <button onClick={updatePricingStatus}>Finalizar</button>
                </>
            }

        </SectionBox>
    )
}

export default RealTimeQuoteForm;