import 'dayjs/locale/pt-br';
import { ActionIcon, Button, Group, Modal, Pagination, Select, Stack, Table, TextInput, Title } from '@mantine/core';
import { DatePicker, TimeInput } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { ContentWrapper, DashboardContent } from 'components/v2/Dashboard/Layout';
import { SectionBox } from 'components/v2/SectionBox';
import React, { useEffect, useState } from 'react';
import { Check, Edit, Filter, Plus, Send, Tool, Trash, X } from 'tabler-icons-react';
import './style.css';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import BackendServiceV2 from 'services/v2/BackendService';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import dayjs from 'dayjs';

export function ToolsPage() {

    const queryClient = useQueryClient();
    
    // TELEPORT LIST
    const [currentPage, setCurrentPage] = useState(1);
    const [status, setStatus] = useState(undefined);

    useEffect(() => {
        setCurrentPage(0);
    }, [status]);

    const {data: scans} = useQuery(['scans-reminder-data', currentPage, status], () => {
        let url = `/scan/reminder?page=${currentPage}`;

        if(status) {
            url += `&status=${status}`
        }

        return BackendServiceV2.get(url);
    });

    function getStatusMessage(status) {
        switch(status) {
            case 'NOT_SENT':
                return "Não enviada";
            case 'SENT':
                return "Enviada";
            case 'DELIVERED':
                return "Entregue";
            case 'READ':
                return "Lida";
            case 'ACCEPTED':
                return "Aceita";
            case 'CONTACT':
                return "Entrar em contato";
            default:
                return "";
        }
    }

    // CREATE SCAN REMINDER
    const [opened, openedHandler] = useDisclosure(false);

    const form = useForm({
        initialValues: {
            phone: '',
            date: '',
            hour: ''
        },
        validate: {
            
        }
    });

    const {mutate: createScanReminder, isLoading} = useMutation((data) => {
        data.date = dayjs(data.date).add(data.hour.getHours(), 'hour').add(data.hour.getMinutes(), 'minute').toDate();
        return BackendServiceV2.post('/scan/reminder', data);
    }, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('scans-reminder-data');
            openedHandler.close();
            form.reset();
            showNotification({
                title: 'Agendamento criado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const {mutate: deleteScanReminder} = useMutation((idScanReminder) => {
        return BackendServiceV2.delete('/scan/reminder/' + idScanReminder);
    }, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('scans-reminder-data');
            showNotification({
                title: 'Agendamento deletado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const {mutate: sendScanReminderNotification} = useMutation((idScanReminder) => {
        return BackendServiceV2.post('/scan/reminder/' + idScanReminder);
    }, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('scans-reminder-data');
            form.reset();
            showNotification({
                title: 'Notificação enviada com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (
        <DashboardContent 
            title="Ferramentas"
            icon={<Tool />}
            isLoading={isLoading}
        >
            <Modal
                opened={opened}
                onClose={openedHandler.close}
                title={
                    <Group><Plus /><Title order={2}>Adicionar agendamento</Title></Group>
                }
                centered
                size="lg"
            >
                <form id="general-teleport-data-form" onSubmit={form.onSubmit(values => createScanReminder({...values}))}>
                    <Stack w="100%">
                        <TextInput 
                            required
                            size="md"
                            label="Telefone"
                            {...form.getInputProps('phone')}
                        />

                        <DatePicker 
                            required
                            size="md"
                            locale="pt-br"
                            label="Data de agendamento" 
                            withAsterisk 
                            {...form.getInputProps('date')}
                        />

                        <TimeInput 
                            required
                            size="md"
                            label="Horas" 
                            {...form.getInputProps('hour')}
                        />

                        <Button type="submit">Salvar</Button>
                    </Stack>
                </form>
            </Modal>

            <ContentWrapper>
                <SectionBox 
                    title="Agendamentos Scan"
                    right={
                        <ActionIcon onClick={openedHandler.open}><Plus color="#ff5e00" /></ActionIcon>
                    }
                >
                    <Group noWrap position="right">
                        <Filter />
                        <Select 
                            placeholder='Notificação'
                            clearable
                            allowDeselect
                            data={[
                              { value: 'NOT_SENT', label: 'Não enviada' }, 
                              { value: 'SENT', label: 'Enviada' },
                              { value: 'DELIVERED', label: 'Entregue' },
                              { value: 'READ', label: 'Lida' },
                              { value: 'ACCEPTED', label: 'Aceita' },
                              { value: 'CONTACT', label: 'Entrar em contato' }
                            ]}
                            value={status}
                            onChange={value => setStatus(value)}
                        />
                    </Group>

                    <Table striped highlightOnHover withColumnBorders withBorder mt="lg" mb="lg">
                        <thead>
                            <tr>
                                <th>Telefone</th>
                                <th>Data</th>
                                <th>Notificação</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>                            
                            {scans?.result?.map(scan => (
                                <tr key={scan.id}>
                                    <th className='table-cell'>{scan.phone}</th>
                                    <th className='table-cell'>{scan.date}</th>
                                    <th className='table-cell'>{getStatusMessage(scan.status)}</th>
                                    <th className='table-cell'>
                                        <Group noWrap>
                                            <ActionIcon><Edit color="#ff5e00" /></ActionIcon>
                                            <ActionIcon onClick={e => deleteScanReminder(scan.id)}><Trash color="#ff5e00" /></ActionIcon>
                                            <ActionIcon onClick={e => sendScanReminderNotification(scan.id)}><Send color="#ff5e00" /></ActionIcon>
                                        </Group>
                                    </th>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {scans?.result?.length > 0 && <>
                        <Pagination 
                            value={scans?.pageNumber + 1} 
                            onChange={(page) => {setCurrentPage(page - 1); queryClient.invalidateQueries('scans-reminder-data')}} 
                            total={scans?.totalPages} 
                        />
                    </>}
                </SectionBox>
            </ContentWrapper>
        </DashboardContent>
    )
}