import { Button, Group, Text } from "@mantine/core";
import { SectionBox } from "components/v2/SectionBox";
import React from "react";
import { useQuery } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { FileZip } from "tabler-icons-react";

export function TeleportLayerFiles({layer}) {

    // LAYERS FILES
    const {data: layerFiles} = useQuery(['teleport-layer-files', layer.id], () => {
        return BackendServiceV2.get(`/layer/${layer.id}/zip`);
    });

    return (
        <SectionBox 
            title="Arquivos" 
            description="Arquivos originais de processamento"
        >
            <Group>
                {layerFiles?.skyboxes && 
                    <Button component="a" href={window.ENV.StaticURL + layerFiles.skyboxes.path} leftIcon={<FileZip />}>Skyboxes</Button>
                }

                {layerFiles?.models && 
                    <Button component="a" href={window.ENV.StaticURL + layerFiles.models.path} leftIcon={<FileZip />}>Models</Button>
                }

                {layerFiles?.vrJson && 
                    <Button component="a" href={window.ENV.StaticURL + layerFiles.vrJson.path} leftIcon={<FileZip />}>VR.json</Button>
                }
            </Group>
            
            {!layerFiles?.skyboxes && !layerFiles?.models && <Text color="dimmed" size="xs" align="center">Nenhum arquivo disponível para download</Text>}
        </SectionBox>
    )
}