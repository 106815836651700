import React, { useEffect, useState } from 'react';
import Tooltip from '../form/Tooltip';
import './style.css';

function SectionBox(props) {

    const [classes, setClasses] = useState(undefined);

    useEffect(() => {       
        if(props.className !== undefined) {
            setClasses('section-box ' + props.className);
        } else {
            setClasses('section-box');
        }
    }, [props]);

    return (
        <div className={classes} >
            <span className="section-header">
                {props.icon && <img src={props.icon} alt="" className={props.iconClass ? props.iconClass : ''} />}
                <span className="left-section">
                    
                    <div style={{
                        'display': 'flex',
                        'alignItems': 'center'
                    }}>
                        <h1>{props.title}</h1> {props.tooltip && <Tooltip message={props.tooltip} />}
                    </div>
                    <p>{props.description}</p>
                </span>
                <span className="right-section">
                    {props.right}
                </span>
            </span>
            <div className="section-content">{props.children}</div>
        </div>
    );
}

export default SectionBox;