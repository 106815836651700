import React, { useEffect, useContext, useRef, useState } from 'react';
import './style.css';
import SectionBox from '../../../../components/SectionBox';
import ShotBox from '../../../../components/ShotBox';
import { useParams } from 'react-router-dom';
import { Badge, Group, LoadingOverlay, Tabs } from '@mantine/core';
import { TeleportCover } from 'components/v2/Teleport/TeleportCover';
import { useQuery } from 'react-query';
import BackendServiceV2 from 'services/v2/BackendService';
import { Armchair, Building, InfoCircle, News, Server, Slideshow, Stack2 } from 'tabler-icons-react';
import { ContentWrapper, DashboardContent, LeftContent, RightContent } from 'components/v2/Dashboard/Layout';
import { TeleportProjectEdit } from 'components/v2/Teleport/TeleportProjectEdit';
import { TeleportShareButton } from 'components/v2/Teleport/TeleportShareButton';
import { TeleportDuplicateButton } from 'components/v2/Teleport/TeleportDuplicateButton';
import { TeleportDeleteButton } from 'components/v2/Teleport/TeleportDeleteButton';
import { Error404 } from 'components/v2/Dashboard/404';
import { TeleportDetail } from 'components/v2/Teleport/TeleportDetail';
import { TeleportPhoto } from 'components/v2/Teleport/TeleportPhoto';
import { TeleportCarousel } from 'components/v2/Teleport/TeleportCarousel';
import { TeleportCarouselContextProvider } from 'components/v2/Teleport/TeleportCarousel/Context/TeleportCarouselContextProvider';
import { TeleportLayer } from 'components/v2/Teleport/TeleportLayer';
import { TeleportPersonalization } from 'components/v2/Teleport/TeleportPersonalization';
import { TeleportScanEdit } from 'components/v2/Teleport/TeleportScanEdit';
import { TeleportPersonalizationCard } from 'components/v2/Teleport/TeleportPersonalization/TeleportPersonalizationCard';
import { MIME_TYPES } from '@mantine/dropzone';
import { TeleportVideoTexture } from 'components/v2/Teleport/TeleportVideoTexture';
import { Publication } from 'components/v2/Publication';
import { ResourcePermissionContext } from 'context/Permission/ResourcePermissionContext';
import { PermissionMenu } from 'components/v2/PermissionMenu';
import TeleportPlayer from 'components/v2/Teleport/TeleportPlayer';
import { PlayerContextProvider } from 'context/Player/PlayerContextProvider';
import queryString from 'query-string';
import { TeleportAddressEdit } from 'components/v2/Teleport/TeleportAddressEdit';
import { TagsEdit } from 'components/v2/Tags/TagEdit';
import { ResourceMove } from 'components/v2/ResourceMove';
import TeleportScanEmbed from 'components/v2/Teleport/TeleportScanEmbed';
import { TeleportObject } from 'components/v2/Teleport/TeleportObject';

export function TeleportEditPage() {
    
    // PARAMS
    const idTeleport = useParams().idTeleport;

    // GET TELEPORT DATA
    const {data: teleport, isLoading, isError} = useQuery(['teleport-data', idTeleport], () => {
        return BackendServiceV2.get('/teleport/' + idTeleport);
    });

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    useEffect(() => {
        resourcePermission.setResource('TELEPORT', idTeleport);
    // eslint-disable-next-line
    }, [idTeleport]);

    // PLAYER REF
    const playerRef = useRef();

    // TABS CONTROL    
    const [currentTab, setCurrentTab] = useState('info');

    useEffect(() => {
        let tab = queryString.parse(window.location.search).tab;
        if(tab)
            setCurrentTab(tab);
    }, []);

    if(isLoading) return (
        <DashboardContent>
            <LoadingOverlay visible={isLoading} />
        </DashboardContent>
    )

    if(isError) return (
        <Error404 />
    )

    return (
        <PlayerContextProvider player={playerRef}>
            <DashboardContent 
                resourceId={teleport?.id}
                resourceClass="TELEPORT"
                title={teleport?.metadata?.title} 
                icon={<Building size={30} />}
                description={
                    <Group spacing={3}>
                        <Badge variant='outline'>{teleport?.id}</Badge>
                        {resourcePermission.containsGroup(['ADMIN']) && teleport?.metadata?.internalName && <Badge variant='outline'>{teleport?.metadata?.internalName}</Badge>}
                    </Group>
                }
                right={<>
                    {resourcePermission.containsGroup(['ADMIN']) && <>
                        <ResourceMove resourceClass="TELEPORT" idResource={teleport.id} />
                        <TeleportDuplicateButton teleport={teleport} />
                        <TeleportDeleteButton teleport={teleport} />
                    </>}

                    <PermissionMenu resourceClass='TELEPORT' resourceId={idTeleport} />
                    <TeleportShareButton teleport={teleport} />
                </>}
            >
                <Tabs 
                    value={currentTab}
                    keepMounted={false}
                    styles={(theme) => ({
                        root: {
                            flex: 1
                        },
                        panel: {
                            height: '100%'
                        }
                    })}
                    onTabChange={value => {
                        let parameters = queryString.parse(window.location.search);
                        parameters['tab'] = value;
                        window.history.pushState('', '', '?' + queryString.stringify(parameters));
                        setCurrentTab(value);
                    }}
                >
                    <Tabs.List>
                        <Tabs.Tab value="info" icon={<InfoCircle />}>Geral</Tabs.Tab>
                        {teleport?.type === 'PROJECT' && <>
                        {resourcePermission.containsPermission('TELEPORT_UPDATE') && <Tabs.Tab value="features" icon={<Slideshow />}>Destaques</Tabs.Tab>}
                            {resourcePermission.containsPermission('TELEPORT_LAYER_READ') && <Tabs.Tab value="layers" icon={<Stack2 />}>Layers</Tabs.Tab>}
                            {resourcePermission.containsPermission('TELEPORT_SHOT_READ') && <Tabs.Tab value="render" icon={<Server />}>Render</Tabs.Tab>}
                        </>}
                        {resourcePermission.containsGroup(['ADMIN']) && <>
                            <Tabs.Tab value="publication" icon={<News />}>Publicação</Tabs.Tab>
                            <Tabs.Tab value="object" icon={<Armchair />}>Objetos</Tabs.Tab>
                        </>}
                    </Tabs.List>

                    <Tabs.Panel value="info" pt="xs">
                        <ContentWrapper>
                            <LeftContent>
                                {teleport?.type === 'PROJECT' ?
                                    <TeleportPlayer teleport={teleport} visible={teleport?.type === 'PROJECT'} ref={playerRef} />
                                :
                                    <TeleportScanEmbed teleport={teleport} visible={teleport?.type === 'SCAN'} ref={playerRef} />
                                }

                                <TeleportAddressEdit teleport={teleport} />

                                {resourcePermission.containsPermission('TELEPORT_UPDATE') &&  <>
                                    {teleport?.type === 'SCAN' && 
                                        <TeleportScanEdit teleport={teleport} />
                                    }

                                    {teleport?.type === 'PROJECT' && 
                                        <TeleportDetail teleport={teleport} />
                                    }

                                    {teleport?.type === 'PROJECT' && 
                                        <TeleportPhoto photos={teleport?.photos} idTeleport={teleport?.id}></TeleportPhoto>
                                    }
                                </>}
                            </LeftContent>

                            <RightContent>
                                {resourcePermission.containsPermission('TELEPORT_UPDATE') && <>

                                    {teleport?.type === 'PROJECT' && 
                                        <TeleportProjectEdit teleport={teleport} />
                                    }

                                    <TeleportCover teleport={teleport} />

                                    {teleport?.type === 'PROJECT' &&
                                        <TeleportPersonalizationCard 
                                            idTeleport={teleport.id}
                                            type="LOGO"
                                            title="Logo"
                                            description="Altera o logo principal do Teleport"
                                            currentPreview={teleport?.personalization?.logo}
                                            accept={[MIME_TYPES.png]}
                                        />
                                    }

                                    <TeleportPersonalizationCard 
                                        idTeleport={teleport.id}
                                        type="SPLASH"
                                        title="Logo de carregamento"
                                        description="Altera o logo exibido na tela de carregamento"
                                        currentPreview={teleport?.personalization?.splash}
                                        accept={[MIME_TYPES.png]}
                                    />

                                    <TeleportPersonalizationCard 
                                        idTeleport={teleport.id}
                                        type="LOADING_BACKGROUND"
                                        title="Fundo de carregamento"
                                        description="Imagem exibida no fundo da tela de carregamento"
                                        currentPreview={teleport?.personalization?.loadBackground?.jpg}
                                        accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
                                    />

                                    <TeleportPersonalizationCard 
                                        idTeleport={teleport.id}
                                        type="VIDEO_COVER"
                                        title="Video de capa"
                                        description="Vídeo exibido em cima da capa durante o carregamento"
                                        currentPreview={teleport?.personalization?.videoCover}
                                        accept={[MIME_TYPES.mp4]}
                                    />

                                    {teleport?.type === 'PROJECT' &&  <>
                                        <TeleportPersonalization teleport={teleport} />

                                        <TeleportVideoTexture idTeleport={teleport?.id} />
                                    </>}
                                </>}
                            </RightContent>
                        </ContentWrapper>
                    </Tabs.Panel>

                    {teleport?.type === 'PROJECT' && <>
                        <Tabs.Panel value="features" pt="xs">
                            <TeleportCarouselContextProvider idTeleport={teleport?.id}>
                                <TeleportCarousel teleport={teleport} />
                            </TeleportCarouselContextProvider>
                        </Tabs.Panel>

                        {(resourcePermission.containsGroup(['STUDIO']) || resourcePermission.containsGroup(['EDITOR_STUDIO']))  &&
                            <Tabs.Panel value="layers" pt="xs">
                                <TeleportLayer idTeleport={teleport?.id}></TeleportLayer>
                            </Tabs.Panel>
                        }

                        {(resourcePermission.containsGroup(['STUDIO']) || resourcePermission.containsGroup(['EDITOR_STUDIO'])) &&
                            <Tabs.Panel value="render" pt="xs">
                                <ContentWrapper>
                                    <SectionBox 
                                        title="Render Service" 
                                        description="" 
                                        right=""
                                        className="full-width">
                                            <ShotBox idTeleport={teleport?.id} />
                                    </SectionBox>
                                </ContentWrapper>
                            </Tabs.Panel>
                        }
                    </>}

                    {(resourcePermission.containsGroup(['ADMIN'])) &&
                        <Tabs.Panel value="publication" pt="xs">
                            <ContentWrapper>
                                <LeftContent>
                                    <TagsEdit resourceClass="TELEPORT" idResource={teleport.id} />
                                </LeftContent>

                                <RightContent>
                                    <Publication publicationType={'TELEPORT'} idResource={teleport.id} />
                                </RightContent>
                            </ContentWrapper>
                        </Tabs.Panel>
                    }

                    {(resourcePermission.containsGroup(['ADMIN'])) &&
                        <Tabs.Panel value="object" pt="xs">
                            <ContentWrapper>
                                <LeftContent>
                                    <TeleportObject teleport={teleport} />
                                </LeftContent>
                            </ContentWrapper>
                        </Tabs.Panel>
                    }
                </Tabs>
            </DashboardContent>
        </PlayerContextProvider>
    );
}