import React, { useRef } from 'react';
import './style.css';
import tooltipIconLight from '../../../assets/tooltip_icon_light.png';

function Tooltip({message}) {

    const tooltip = useRef(undefined);

    async function tooltipClick(e) {
        if(e.currentTarget !== e.target) return;
        
        e.preventDefault();
        e.stopPropagation();

        if(tooltip === undefined && tooltip.current === undefined && tooltip.current === null)
            return;

        if(tooltip.current.style.display === 'none' || tooltip.current.style.display === '') {
            tooltip.current.style.display = 'flex';
        } else {
            tooltip.current.style.display = 'none';
        }
    }

    async function tooltipHide(e) {
        e.preventDefault();
        e.stopPropagation();
        
        tooltip.current.style.display = 'none';
    }

    async function tooltipShow(e) {
        e.preventDefault();
        e.stopPropagation();
        
        tooltip.current.style.display = 'flex';
    }

    return (
        <div className="tooltip" onClick={tooltipClick} onMouseLeave={tooltipHide} onMouseEnter={tooltipShow}>
            <span className="tooltip-content" ref={tooltip}>
                <img src={tooltipIconLight} alt="" />
                <span>{message}</span>
            </span>
        </div>
    )
}

export default Tooltip;