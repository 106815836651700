import React, {useCallback, useState, useRef, useContext} from 'react';
import BackendService from '../../services/BackendService';
import SectionBox from '../../components/SectionBox';
import Modal from '../Modal';
import Pagination from '../../components/Pagination';
import Message from '../../components/Message';
import FileUploader from '../../components/FileUploader';
import ConfirmationModal from '../Modal/ConfirmationModal';
import { ResourcePermissionContext } from 'context/Permission/ResourcePermissionContext';

function ShotBox({idTeleport}) {

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    // NOTIFICATION
    const [resultMessage, setResultMessage] = useState(undefined);
    const [typeMessage, setTypeMessage] = useState(undefined);

    // SHOT PAGINATION
    const [shots, setShots] = useState([]);
    const [shotTotalPages, setShotTotalPages] = useState(undefined);
    const [shotCurrentPage, setShotCurrentPage] = useState(undefined);

    // PHASE RESULT FILES MODAL
    const [modalPhaseId, setModalPhaseId] = useState(undefined);
    const [modalPhaseResultFiles, setModalPhaseResultFiles] = useState([]);

    // CREATE SHOT MODAL
    const [modalShotOpen, setModalShotOpen] = useState(undefined);

    // NEW PROCESSING INPUT FORM
    const [phase, setPhase] = useState("SKYBOX_VALIDATION");
    const [shotPhaseFrames, setShotPhaseFrames] = useState(null);
    const processingFileInputRef = useRef(null);
    const [progress, setProgress] = useState(undefined);

    function showMessage(message, isError) {
        setResultMessage(message);
        isError ? setTypeMessage('error') : setTypeMessage('success');
    }

    function cleanMessage() {
        setResultMessage(undefined);
        setTypeMessage(undefined);
    }
    
    const getShot = useCallback(async(page) => {
        if(idTeleport !== undefined && page !== undefined) {
            let response = await BackendService.get('/teleport/' + idTeleport + '/shot?size=8&page=' + page);

            if(response.status === 200) {
                setShots(response.data.result);
                setShotTotalPages(response.data.totalPages);
                setShotCurrentPage(page);
            } else {
                showMessage(response.data.message, true);
            }
        }
    }, [idTeleport]);
    
    const stopRender = useCallback(async(idShot) => {
        let response = await BackendService.put('/teleport/shot/' + idShot + '/finish');
        
        if(response.status === 200) {
            showMessage('Finishing rendering of shot ' + idShot, false);
        } else {
            showMessage('Failed to stop rendering of shot ' + idShot, true)
        }
    }, []);

    function displayFrames(frames, phase) {
        if(phase === "SKYBOX_PRODUCTION" || phase === "SKYBOX_VALIDATION" || phase === "VIDEO_PRODUCTION") {
            if(frames !== null) {
                return frames;
            } else {
                return "All";
            }

            
        } else {
            return "-";
        }
    }

    const createNewShot = useCallback(async () => {
        let response = await BackendService.post('/teleport/' + idTeleport + '/shot', {
            renderPhase: phase,
            frames: shotPhaseFrames
        });

        if(response.status === 201) {
            return response.data;
        } else {
            showMessage(response.data.message, true);
        }
    }, [phase, idTeleport, shotPhaseFrames]);

    const { BlobServiceClient } = require("@azure/storage-blob");

    // FORM BUTTON
    const newShotButtonRef = useRef(undefined);

    async function submitNewShotRequest(e) {
        if(newShotButtonRef && newShotButtonRef.current)
            newShotButtonRef.current.disabled = true;
            
        try {
            e.preventDefault();
            cleanMessage();

            let shot = await createNewShot();
            if(processingFileInputRef.current.files.length === 1) {
                let uploadURLresponse = await BackendService.get('/teleport/shot/' + shot.id + '/base');

                if(uploadURLresponse.status === 200) {
                    const blobServiceClient = new BlobServiceClient(window.ENV.StaticURL + uploadURLresponse.data.signedURL);
                    
                    const containerClient = blobServiceClient.getContainerClient('main-storage');
                    const blockBlobClient = containerClient.getBlockBlobClient(uploadURLresponse.data.entityDir + uploadURLresponse.data.filename);

                    let response = await blockBlobClient.uploadData(processingFileInputRef.current.files[0], {
                        onProgress: (progressEvent) => {
                            if(processingFileInputRef.current.files.length === 1)
                                setProgress(Math.round((progressEvent.loadedBytes * 100) / processingFileInputRef.current.files[0].size));
                        }
                    });

                    if(response._response.status === 201) {
                        let renderResponse = await BackendService.post('/teleport/shot/' + shot.id + '/queue');

                        if(renderResponse.status === 200) {
                            showMessage(renderResponse.data.message);
                        } else {
                            showMessage(renderResponse.data.message, true);
                        }
                    }

                    setModalShotOpen(undefined);
                    getShot(shotCurrentPage);
                } else {
                    showMessage('Failed to generate upload URL', true);
                }
            } else {
                showMessage('No file selected', true);
            }
        } catch (error) {
            error.response !== undefined ? showMessage(error.response.data.message, true) : showMessage(error.message, true);
        }
        
        if(newShotButtonRef && newShotButtonRef.current)
            newShotButtonRef.current.disabled = false;
    }

    // DELETE SHOT
    const [shotDeleteModalData, setShotDeleteModalData] = useState(undefined);

    async function deleteShot() {
        if(!shotDeleteModalData)
            return;

        try {
            cleanMessage();

            let response = await BackendService.delete('/teleport/shot/' + shotDeleteModalData.id);
        
            if(response.status === 200) {
                showMessage(response.data.message, false);
                getShot(shotCurrentPage);
            } else {
                showMessage(response.data.message, true);
            }
        } catch (error) {
            error.response !== undefined ? showMessage(error.response.data.message, true) : showMessage(error.message, true);
        }    
    }

    return (
        <SectionBox 
            title="Shots" 
            description="Render skyboxes and bake on Render Service"
            right={(
                <button onClick={() => {
                    setModalShotOpen(0);
                }}>New Shot</button>
            )}
            className="shots"
        >
            <Message message={resultMessage} type={typeMessage} />

            <Modal openModal={modalShotOpen} title={"Create new Shot"} setOpenModal={setModalShotOpen}>
                <form onSubmit={submitNewShotRequest}>        
                    <span className="form-input">
                        <span>Phase</span>

                        <select id="phase" value={phase} onChange={e => setPhase(e.target.value)}>
                            <option value="SKYBOX_VALIDATION">Skybox validation</option>
                            <option value="SKYBOX_PRODUCTION">Skybox production</option>
                            <option value="BAKE_PRODUCTION">Bake production</option>
                            <option value="VIDEO_PRODUCTION">Video production</option>
                        </select>
                    </span>

                    {(phase === "SKYBOX_PRODUCTION" || phase === "SKYBOX_VALIDATION" || phase === "VIDEO_PRODUCTION") &&
                        <span className="form-input">
                            <span>Frames</span>
                            <input type="text" placeholder="0,1,2-5" onChange={(e) => setShotPhaseFrames(e.target.value)}></input>
                        </span>
                    }

                    <span className="form-input">
                        <span>File</span>
                        
                        <input className="file-upload-input" type="file" name="file" ref={processingFileInputRef} accept=".zip" />
                        <FileUploader hiddenInput={processingFileInputRef} progress={progress} autoSubmit={false}  />
                    </span>

                    <button ref={newShotButtonRef}>Start</button>
                </form>
            </Modal>

            <Modal openModal={modalPhaseId} title={"Result files to Phase " + modalPhaseId} setOpenModal={setModalPhaseId}>
                {modalPhaseResultFiles.map(resultFile => (
                    <a className="link-list" href={window.ENV.StaticURL + resultFile.path}>{resultFile.relativePath}</a>
                ))}
                
                {modalPhaseResultFiles.length === 0 && <div>No file found.</div>}
            </Modal>

            <ConfirmationModal 
                openModal={shotDeleteModalData} 
                setOpenModal={setShotDeleteModalData} 
                title={shotDeleteModalData ? `Are you sure you want to delete the shot ${shotDeleteModalData.id} ?` : ''}
                callback={deleteShot} />

            <div className="grid">
                {shots.map(shot => (
                    <div className="grid-entry old-grid" key={shot.id}>
                        <div className="grid-content">
                            <div className="grid-header">
                                <b>{shot.id}</b>
                                {shot.status === "RENDERING" &&
                                    <button 
                                        className="secundary right" 
                                        onClick={() => stopRender(shot.id)}>
                                            Stop
                                    </button>
                                }

                                {shot.status === "NEW" &&
                                    <button 
                                    className="secundary right" 
                                    onClick={() => setShotDeleteModalData(shot)}>
                                        Delete
                                    </button>
                                }
                            </div>
                            
                            <div className="grid-body">
                                <ul>
                                    <li><b>Render Phase:</b> {shot.renderPhase}</li>
                                    <li><b>Status:</b> {shot.status}</li>
                                    <li><b>Created:</b> {shot.createdAt}</li>
                                    <li><b>Frames:</b> {displayFrames(shot.frames, shot.renderPhase)}</li>
                                    
                                    {resourcePermission.containsGroup(['ADMIN']) &&
                                        <li><b>Base files:</b>{shot.baseFile && <a href={window.ENV.StaticURL + shot.baseFile}>Download</a>}</li>
                                    }

                                    <li>
                                        <b>Result files:</b>
                                        {shot.resultFilesZip ? 
                                            <>
                                                {shot.resultFilesZip && <a href={window.ENV.StaticURL + shot.resultFilesZip}>Download</a>}
                                            </>
                                            : 
                                            'Not Avaliable'                                        
                                        }
                                    </li>
                                    <li>
                                        {shot.status === "RENDERING" || shot.status === "FINISHED" ? 
                                            <button className="grid-button" onClick={() => {
                                                setModalPhaseId(shot.id);
                                                setModalPhaseResultFiles(shot.resultFiles);
                                            }}>Preview</button>
                                            : ''
                                        }
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <Pagination totalPages={shotTotalPages} onPageChange={getShot} pathname={"/teleport/" + idTeleport} />
        </SectionBox>
    )
}

export default ShotBox;