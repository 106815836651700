import { Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import UploadArea from "components/v2/UploadArea";
import { PlayerContext } from "context/Player/PlayerContext";
import React, { useContext, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";

export function TeleportPersonalizationCard({idTeleport, type, title, description, currentPreview, accept}) {

    const queryClient = useQueryClient();

    const playerContext = useContext(PlayerContext);

    // UPDATE AND UPLOAD
    const uploadArea = useRef(null);

    function updatePersonalization() {
        if(!uploadArea?.current || !uploadArea.current.hasFile()) return;

        uploadArea.current.upload({
            mutate: async (files, config) => {
                let formData = new FormData();
                formData.append('data', files[0]);

                return BackendServiceV2.put(`/teleport/${idTeleport}/personalization/${type}`, formData, config)
            },
            success: () => {
                queryClient.invalidateQueries('teleport-data');
                playerContext?.player?.reload();

                showNotification({
                    title: 'Personalização atualizada com sucesso',
                    message: '',
                    icon: <Check />,
                    color: 'green',
                    autoClose: 5000,
                });
            },
            error: (error) => {
                showNotification({
                    title: 'Um erro ocorreu',
                    message: error?.response?.data?.message,
                    icon: <X />,
                    color: 'red',
                    autoClose: 5000,
                });
            }
        });
    }

    // DELETE
    const {mutate: deletePersonalization} = useMutation(() => {
        return BackendServiceV2.delete(`/teleport/${idTeleport}/personalization/${type}`);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-data');
            playerContext?.player?.reload();

            showNotification({
                title: 'Personalização atualizada com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    })

    const modals = useModals();

    const deletePersonalizationConfirmation = () => modals.openConfirmModal({
        title: 'Deletar personalização',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja deletar a personalização {title}?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            deletePersonalization();
        }
    });

    return (
        <SectionBox
            title={title}
            description={description}
        >
            <UploadArea
                size="md"
                currentPreview={currentPreview ? currentPreview : undefined}
                deleteCurrent={deletePersonalizationConfirmation}
                maxSize={1024 * 5}
                accept={accept}
                multiple={false}
                ref={uploadArea}
                topPreview={true}
                onDrop={updatePersonalization}
            />
        </SectionBox>
    )
}