import { ActionIcon, Badge, Button, Group, Loader, Progress, SegmentedControl, Stack, Text, TextInput, Title } from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, FileUpload, Trash, X } from "tabler-icons-react";
import equal from'fast-deep-equal';
import { useModals } from "@mantine/modals";
import { CopyTextInput } from "components/v2/Form/CopyTextInput";

export function TeleportVideoTextureCard({videoTexture}) {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // UPDATE FORM
    const form = useForm({
        initialValues: {
            maxDistance: videoTexture.maxDistance / 10,
            origin: videoTexture.origin || ''
        },
        validate: {
        },
    });

    // UPDATE DATA ON QUERY INVALIDATE AND AUTO LIVE STARTING UPDATE
    useEffect(() => {
        form.setValues({
            maxDistance: videoTexture.maxDistance / 10
        });

        let updateInterval;
        if(videoTexture?.stream && videoTexture?.stream?.status !== 'RUNNING') {
            updateInterval = setInterval(() => {
                console.log("UPDATING VIDEO TEXTURE DATA");
                queryClient.invalidateQueries('teleport-video-texture');
            }, 10000);
        } else {
            if(updateInterval)
                clearInterval(updateInterval)
        }

        return () => {
            if(updateInterval)
                clearInterval(updateInterval);
        }
    // eslint-disable-next-line
    }, [videoTexture]);

    // AUTO UPDATE ON CHANGE
    const [debouncedFormValues] = useDebouncedValue(form.values, 1000);

    useEffect(() => {
        if(!equal({maxDistance: videoTexture.maxDistance / 10, origin: videoTexture.origin}, debouncedFormValues))
            updateVideoTextureData.mutate(debouncedFormValues);
    // eslint-disable-next-line
    }, [debouncedFormValues])

    // UPDATE DATA
    const updateVideoTextureData = useMutation((value) => {       
        return BackendServiceV2.put(`/videotexture/${videoTexture.id}`, {
            maxDistance: value.maxDistance * 10,
            origin: value.origin
        });
    }, {
        onSuccess: data => {
            showNotification({
                title: 'Vídeo atualizado com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
            queryClient.invalidateQueries('teleport-video-texture');
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    // UPDATE VIDEO TEXTURE
    const startVideoTextureUpload = useMutation(files => {
        if(files.length !== 1) return Promise.reject();
        return BackendServiceV2.put(`/videotexture/${videoTexture.id}/video`);
    }, {
        onSuccess: (data, files) => {
            uploadVideoTexture.mutate({data, files});
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    const { BlobServiceClient } = require("@azure/storage-blob");
    const [ uploadProgress, setProgress] = useState(undefined);

    const uploadVideoTexture = useMutation(({data, files}) => {
        const blobServiceClient = new BlobServiceClient(window.ENV.StaticURL + data.signedURL);
                    
        const containerClient = blobServiceClient.getContainerClient('main-storage');
        const blockBlobClient = containerClient.getBlockBlobClient(data.entityDir + data.filename);

        return blockBlobClient.uploadData(files[0], {
            onProgress: (progressEvent) => {
                setProgress(Math.round((progressEvent.loadedBytes * 100) / files[0].size));
            }
        });
    }, {
        onSuccess: data => {
            console.log(data);
            setProgress(undefined);
            queryClient.invalidateQueries('teleport-video-texture');
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    // DELETE VIDEO
    const modals = useModals();
    const deleteVideoTextureConfirmation = () => modals.openConfirmModal({
        title: 'Deletar textura',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja deletar a textura {videoTexture.name}?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            deleteVideoTexture.mutate();
        },
        zIndex: 1000
    });

    const deleteVideoTexture = useMutation(() => {
        return BackendServiceV2.delete(`/videotexture/${videoTexture.id}`);
    }, {
        onSuccess: data => {
            showNotification({
                title: 'Vídeo removido com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
            queryClient.invalidateQueries('teleport-video-texture');
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    // START LIVESTREAMING
    const startLiveStreaming = useMutation(() => {
        return BackendServiceV2.post(`/videotexture/${videoTexture.id}/live`);
    }, {
        onSuccess: data => {
            showNotification({
                title: 'Transmissão ao vivo iniciando.',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
            queryClient.invalidateQueries('teleport-video-texture');
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    // CLOSE LIVESTREAMING
    const closeLiveStreaming = useMutation(() => {
        return BackendServiceV2.delete(`/videotexture/${videoTexture.id}/live`);
    }, {
        onSuccess: data => {
            showNotification({
                title: 'Transmissão ao vivo encerrada.',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
            queryClient.invalidateQueries('teleport-video-texture');
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })
    
    return (
        <Stack spacing={5} style={{border: '1px solid #eee', padding: '15px', borderRadius: '5px'}}>
            <Group position="apart">
                <Group>
                    <Title order={4}>{videoTexture.name}</Title>
                    <Badge variant="outline" size="xs">{videoTexture.id}</Badge>
                </Group>
                <ActionIcon variant="transparent" onClick={e => {deleteVideoTextureConfirmation()}} color="#ff5e00"><Trash /></ActionIcon>
            </Group>

            <TextInput 
                label="Distancia máxima"
                type="number"
                rightSection={<Group align="left"><Text color="dimmed" size="sm">Metros</Text></Group>}
                rightSectionWidth={60}
                {...form.getInputProps('maxDistance')}
            />

            {videoTexture?.stream?.status !== 'RUNNING' &&
                <SegmentedControl
                    data={[
                        { label: 'Video', value: 'VIDEO' },
                        { label: 'Live', value: 'LIVE' },
                    ]}
                    {...form.getInputProps('origin')}
                />
            }

            {videoTexture.origin === 'LIVE' && <>
                {videoTexture.stream ?
                    <>
                        {videoTexture.stream.status === 'RUNNING' && <>
                            <CopyTextInput
                                label="Servidor RTMP"
                                value={'rtmp://' + videoTexture.stream.ip + '/live'}
                            />

                            <CopyTextInput
                                label="Chave"
                                notificationMessage="Chave copiada com sucesso"
                                value={videoTexture.stream.key}
                            />

                            <Button title="Iniciar livestream" onClick={e => {closeLiveStreaming.mutate()}}>Encerrar livestream</Button>
                        </>}

                        {videoTexture.stream.status !== 'RUNNING' && <>
                            <Stack align="center" spacing="xs" mt="xs">
                                <Loader />
                                <Text size="xs" align="center" color="dimmed">Transmissão iniciando, por favor, aguarde.</Text>
                            </Stack>
                        </>}
                    </>
                :
                    <Button title="Iniciar livestream" onClick={e => {startLiveStreaming.mutate()}}>Iniciar livestream</Button>
                }
            </>}

            {videoTexture.origin === 'VIDEO' && <>
                <Text weight={600} style={{fontSize: '14px'}}>Vídeo</Text>
                {videoTexture.path && <>
                    <video width="100%" height={160} src={window.ENV.StaticURL + videoTexture.path} controls style={{backgroundColor: 'black'}}>
                        Your browser does not support the video tag.
                    </video>
                </>}

                <Dropzone
                    onDrop={(files) => {startVideoTextureUpload.mutate(files)}}
                    onReject={(files) => {
                        showNotification({
                            color: 'red',
                            title: 'O arquivo precisa ser do formato .mp4 com menos de 500MB de tamanho',
                            message: '',
                            autoClose: 5000,
                            icon: <X />
                        });
                    }}
                    maxSize={900 * 1024 ** 2}
                    accept={[MIME_TYPES.mp4]}
                    multiple={false}
                >
                    {uploadVideoTexture.isLoading ? 
                        <Progress value={uploadProgress} size="xl" label={(uploadProgress ? uploadProgress : 0) + "%"} />
                    :
                        <Group position="center" spacing="xs" style={{ minHeight: 50, pointerEvents: 'none' }}>
                            <FileUpload size={20} />
                            <Text size="sm" inline align="center">
                                Solte seu video ou clique aqui para selecionar um arquivo
                            </Text>
                        </Group>
                    }
                </Dropzone>
            </>}
        </Stack>
    )    
}