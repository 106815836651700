import React from 'react';
import Tooltip from '../Tooltip';
import './style.css';

function Toggle({value, onValueChange, label, tooltip}) {
    
    async function changeValue(e) {
        e.preventDefault();
        e.stopPropagation();

        onValueChange(!value);
    }

    return (
        <div className="form-toggle" onClick={changeValue}>
            <div className={'toggle-background ' + (value ? 'toggle-background-selected' : '')} >
                <div className={'toggle-circle ' + (value ? 'toggle-circle-selected' : '')}></div>
            </div>
            <span className="toggle-label">{label}</span>{tooltip && <Tooltip message={tooltip} />}
        </div>
    )
}

export default Toggle;