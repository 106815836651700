import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { ContentWrapper, DashboardContent, LeftContent, RightContent } from 'components/v2/Dashboard/Layout';
import { SectionBox } from 'components/v2/SectionBox';
import { IconUserScreen } from '@tabler/icons-react';
import { Badge, Group, LoadingOverlay, Stack } from '@mantine/core';
import './style.css';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import BackendServiceV2 from 'services/v2/BackendService';
import { Error404 } from 'components/v2/Dashboard/404';
import { PermissionMenu } from 'components/v2/PermissionMenu';
import { ResourcePermissionContext } from 'context/Permission/ResourcePermissionContext';
import { TotemDeleteButton } from 'components/v2/Totem/TotemDeleteButton';
import { TotemAddPage } from 'components/v2/Totem/TotemAddPage';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { TotemPageCard } from 'components/v2/Totem/TotemPageCard';
import { TotemEdit } from 'components/v2/Totem/TotemEdit';
import { showNotification } from '@mantine/notifications';
import { Check, X } from 'tabler-icons-react';

export function TotemEditPage() {

    // PARAMS
    const idTotem = useParams().idTotem;

    const queryClient = useQueryClient();

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    useEffect(() => {
        resourcePermission.setResource('TOTEM_INTERFACE', idTotem);
    // eslint-disable-next-line
    }, [idTotem]);

    // GET TOTEM DATA
    const {data: totem, isLoading, isError} = useQuery(['totem-data', idTotem], () => {
        return BackendServiceV2.get('/totem/' + idTotem);
    });

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )

    const [pagesSort, setPagesSort] = useState(totem?.pages?.map(page => (page?.id)) || [])

    useEffect(() => {
        setPagesSort(totem?.pages?.map(page => (page?.id)) || [])
    }, [totem?.pages])

    function onDragEnd(event) {
        const {active, over} = event;

        if (active.id !== over.id) {
            setPagesSort((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);
                return arrayMove(items, oldIndex, newIndex);
            });

            updatePagesOrder({id: active.id, position: over.data.current.sortable.index + 1});
        }
    }

    const {mutate: updatePagesOrder} = useMutation((data) => {
        console.log("SETTING TO ORDER " + data.position);
        return BackendServiceV2.put('/totem/page/' + data.id, {
            position: data.position
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('totem-data');            
            showNotification({
                title: 'Página atualizada com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    })

    if(isLoading || !totem) return (
        <DashboardContent>
            <LoadingOverlay visible={isLoading} />
        </DashboardContent>
    )

    if(isError) return (
        <Error404 />
    )

    return (
        <DashboardContent 
            resourceId={totem?.id}
            resourceClass="TOTEM_INTERFACE"
            title={totem.name} 
            icon={<IconUserScreen size={30} />}
            description={
                <Group spacing={3}>
                    <Badge variant='outline'>{totem?.id}</Badge>
                    <Badge variant='outline'>{totem?.type}</Badge>
                    <Badge>{dayjs(totem?.modifiedAt).locale('pt-br').format('dddd, D [de] MMMM HH:mm:ss')}</Badge>
                </Group>
            }
            right={<>
                {resourcePermission.containsGroup(['ADMIN']) && <>
                    <TotemDeleteButton totem={totem} />
                </>}

                <PermissionMenu resourceClass='TOTEM_INTERFACE' resourceId={idTotem} />
            </>}
        >
            <ContentWrapper>
                <LeftContent>
                    <SectionBox title="Páginas" right={<TotemAddPage totem={totem} />}>
                        {totem.type === 'CAVE' &&
                            <DndContext collisionDetection={closestCenter} sensors={sensors} onDragEnd={onDragEnd}>
                                <SortableContext items={pagesSort}>
                                    <Stack align="center" style={{width: '100%'}}>
                                        {pagesSort?.map(idPage => {
                                            let page = totem?.pages?.find(page => page?.id === idPage);

                                            if(!page) return <></>

                                            return (
                                                <TotemPageCard key={totem.id} totem={totem} page={page} />
                                            )
                                        })}
                                    </Stack>
                                </SortableContext>
                            </DndContext>
                        }

                        {totem.type === 'TOTEM' &&
                            <Group>
                                <DndContext collisionDetection={closestCenter} sensors={sensors} onDragEnd={onDragEnd}>
                                    <SortableContext items={pagesSort}>
                                        {pagesSort?.map(idPage => {
                                            let page = totem?.pages?.find(page => page?.id === idPage);

                                            if(!page) return <></>

                                            return (
                                                <Stack key={page.id} style={{width: '368px'}}>
                                                    <TotemPageCard totem={totem} page={page} />
                                                </Stack>
                                            )
                                        })}
                                    </SortableContext>
                                </DndContext>
                            </Group>
                        }
                    </SectionBox>
                </LeftContent>

                <RightContent>
                    <TotemEdit totem={totem} />
                </RightContent>
            </ContentWrapper>
        </DashboardContent>
    );
}