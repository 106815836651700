import React, { useEffect, useState } from 'react';
import Tooltip from '../../form/Tooltip';
import BackendService from '../../../services/BackendService';
import UserService from '../../../services/UserService';

function ServiceAmbientInfo({pricingRequest, baseService, serviceAmbient, setPricingRequest, setIsSaving, isReadOnly}) {

    // PERMISSIONS
    const [ADMIN_ROLE, setAdminRole] = useState(false);

    useEffect(() => {
        async function getPermissions() {
            setAdminRole(await UserService.hasRole('ADMIN'));
        }
        
        getPermissions();
    }, []);

    const [serviceAmbientLocal, setServiceAmbientLocal] = useState(serviceAmbient);

    async function handleAmbientDelete(e, idAmbient) {
        e.preventDefault();

        let response = await BackendService.delete('pricing/request/' + pricingRequest.id + '/ambient/' + idAmbient)

        if(response && response.status <= 201) {
            setPricingRequest(response.data);
        }
    }

    useEffect(() => {
        if(serviceAmbientLocal === serviceAmbient)
            return;

        const delayDebounceFn = setTimeout(async () => {
            setIsSaving(true);

            await BackendService.put(`/pricing/request/${pricingRequest.id}/service/${baseService.id}/ambient/${serviceAmbient.id}`, {
                price: serviceAmbientLocal.price,
                deliveryTime: serviceAmbientLocal.deliveryTime
            });

            setIsSaving(false);
        }, 3000)
    
        return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
    }, [serviceAmbientLocal]);

    return (
        <div className="card-wrapper">
            <div className="card-header">
                <div className="card-title">
                    {serviceAmbientLocal.id + " - " + serviceAmbientLocal.ambient.value}
                </div>
                <div className="card-buttons">
                    {!isReadOnly &&
                        <button onClick={e => {handleAmbientDelete(e, serviceAmbientLocal.ambient.id)}} className="secundary light">Remover</button>
                    }
                </div>
            </div>

            {ADMIN_ROLE &&
            <div className="card-content">
                {false && <div className="form-field">
                    <label>Nome do ambiente</label><Tooltip message="Um nome único nesse orçamento para identificar o ambiente na planta. Ex: Banheiro Suíte 1" />
                    <input type="text" />
                </div>}

                <div className="form-group">
                    <div className="form-field">
                        <label>Preço</label>
                        
                        <div className="pre-input">
                            <div>R$</div>
                            <input 
                                type="text"
                                value={serviceAmbientLocal.price}
                                name="price"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setServiceAmbientLocal({...serviceAmbientLocal, [e.target.name] : e.target.value})
                                }} />
                        </div>
                    </div>

                    <div className="form-field">
                        <label>Entrega (Dias)</label>
                        <input 
                            type="text" 
                            value={serviceAmbientLocal.deliveryTime}
                            name="deliveryTime"
                            onChange={(e) => {
                                e.preventDefault();
                                setServiceAmbientLocal({...serviceAmbientLocal, [e.target.name] : e.target.value})
                            }} />
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default ServiceAmbientInfo;