import React, { useEffect, useState } from 'react';
import Tooltip from '../../form/Tooltip';
import BackendService from '../../../services/BackendService';
import UserService from '../../../services/UserService';
import Toggle from '../../form/Toggle';
import Select from '../../form/Select';

function ServiceInfo({serviceDataDB, servicesDataForm, idPricingRequest, setPricingRequest, ambients, teleportType, setIsSaving}) {

    // PERMISSIONS
    const [ADMIN_ROLE, setAdminRole] = useState(false);

    useEffect(() => {
        async function getPermissions() {
            setAdminRole(await UserService.hasRole('ADMIN'));
        }

        getPermissions();
    }, []);

    // SERVICE DATA
    const[serviceData, setServiceData] = useState({});

    useEffect(() => {
        if(serviceDataDB) {
            if(serviceDataDB.childServices === undefined)
                serviceDataDB.childServices = [];

            if(serviceDataDB.extraData)
                setAdditionalData(serviceDataDB.extraData);

            setServiceData(serviceDataDB);
        } else {
            setServiceData({});
        }
    }, [serviceDataDB]);

    // SERVICE TOGGLE
    async function handleServiceToggle(e, serviceType, serviceData) {
        let response;

        if(serviceData.id) {
            response = await BackendService.delete('/pricing/request/' + idPricingRequest + '/service/' + serviceData.id);
        } else {
            response = await BackendService.put('/pricing/request/' + idPricingRequest + '/service', {
                type: serviceType
            });
        }

        if(response && response.status <= 201) {
            setPricingRequest(response.data);
        }
    }

    function checkIfContainsService(service) {
        if(!serviceData || !serviceData.childServices)
            return false;

        let serviceFind = serviceData.childServices.find(item => item.type === service);
        
        if(serviceFind) {
            return true;
        } else {
            return false;
        }
    }

    // UPDATE PRICE
    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if(serviceData.price && serviceData.price !== serviceDataDB.price) {
                setIsSaving(true);

                await BackendService.put('/pricing/request/' + idPricingRequest + '/service/' + serviceData.id, {
                    ...serviceData
                });

                setIsSaving(false);
            }
        }, 3000)
    
        return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line
    }, [serviceData.price])

    // AMBIENTS
    async function toggleAmbientService(value) {
        let checkedAmbient;
        let response;

        if(serviceData.ambients)
            checkedAmbient = serviceData.ambients.find(item => item.ambient.id === value.id);
        
        if(checkedAmbient) {
            response = await BackendService.delete('/pricing/request/' + idPricingRequest + '/service/' + serviceData.id + '/ambient/' + checkedAmbient.id)
        } else {
            response = await BackendService.put('/pricing/request/' + idPricingRequest + '/service/' + serviceData.id + '/ambient', value)
        }

        if(response && response.status <= 201) {
            setPricingRequest(response.data);
        }
    }

    // HUMANIZED PLANT
    // TODO save humanized plant service

    // TOGGLE CHILDS SERVICES
    async function toggleChildService(value) {
        let checkedService;
        let response;

        if(serviceData.childServices)
            checkedService = serviceData.childServices.find(item => item.type === value);

        if(checkedService) {
            response = await BackendService.delete('/pricing/request/' + idPricingRequest + '/service/' + checkedService.id);
        } else {
            response = await BackendService.put('/pricing/request/' + idPricingRequest + '/service', {
                type: value,
                idParent: serviceData.id
            });
        }

        if(response && response.status <= 201) {
            setPricingRequest(response.data);
        }
    }

    // SERVICE ADDITIONAL DATA
    const [additionalData, setAdditionalData] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({});

    const [quotaOptions] = useState([{'id': 'WITHOUT_QUOTA','value': 'Sem cotas'},{'id': 'WITH_MAIN_WALL_QUOTA','value': 'Cotas nas paredes principais'}]);

    useEffect(() => {
        // Parse select options to select correct option from ID
        if(additionalData.quota) {
            let selectOption = quotaOptions.find(item => item.id === additionalData.quota);
            setSelectedOptions({quota: selectOption});
        }

        const delayDebounceFn = setTimeout(async () => {
            // Parse select options to save only ID
            if(additionalData.quota) {
                additionalData.quota = additionalData.quota.id
            }

            if(idPricingRequest && serviceData.id && additionalData && Object.keys(additionalData).length > 0 && additionalData !== serviceDataDB.extraData) {
                let response = await BackendService.put('/pricing/request/' + idPricingRequest + '/service/' + serviceData.id + '/extradata', additionalData);

                if(response && response.status <= 201) {
                    setPricingRequest(response.data);
                }
            }
        }, 3000)
    
        return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
    }, [additionalData])

    return (
        <>
        <div className="form-field" >
            <input 
                type="checkbox" 
                id={servicesDataForm.id}
                name={servicesDataForm.id}
                checked={serviceData.id ? true : false}
                onChange={(e) => {
                    handleServiceToggle(e, servicesDataForm.id, serviceData)
                }} /><label htmlFor={servicesDataForm.id}>{servicesDataForm.label}</label>{servicesDataForm.tooltip && <Tooltip message={servicesDataForm.tooltip} />}
        </div>

        {ADMIN_ROLE && serviceData.id && !servicesDataForm.isAmbient &&  
            <div className="sub-form">
                <div className="form-field">
                    <label htmlFor={'price-' + serviceData.id}>Preço</label>
                    <input 
                        type="text" 
                        id={'price-' + serviceData.id}
                        name="price"
                        value={serviceData.price}
                        onChange={(e) => {
                            setServiceData({...serviceData, price: e.target.value})
                        }} 
                    />
                </div>
            </div>
        }

        {(servicesDataForm.id === 'VR_3D' || servicesDataForm.id === 'PERSPECTIVE') && serviceData.id && 
            <div className="sub-form">
                <h3>Ambientes</h3>
                {ambients.length === 0 && <p>Nenhum ambiente selecionado no passeio</p>}
                {ambients.map(ambient => {
                    let checkedAmbient;

                    if(serviceData.ambients)
                        checkedAmbient = serviceData.ambients.find(item => item.ambient.id === ambient.id);

                    return (
                        <Toggle 
                            key={ambient.id}
                            label={ambient.value}
                            value={checkedAmbient ? true : false}
                            onValueChange={value => {toggleAmbientService(ambient)}}
                        />
                    )
                })}
            </div>
        }

        {servicesDataForm.id === 'HUMANIZED_PLANT' && serviceData.id && 
            <div className="sub-form">
                <div className="form-group">
                    {/*<Select
                        value={humanizedPlantSelectedOption}
                        onValueChange={value => {
                            addChildService(value);
                        }}
                        name="visualStyle"
                        options={humanizedPlantOptions}
                        label="Estilo Visual">
                    </Select>*/}
                    
                    <Select
                        value={selectedOptions}
                        onValueChange={value => {
                            setAdditionalData({...additionalData, 'quota' : value})
                        }}
                        name="quota"
                        options={quotaOptions}
                        label="Cotas">
                    </Select>
                </div>

                <div className="form-group">
                    <Toggle 
                        label="Nome dos cômodos"
                        value={additionalData.ambientName}
                        onValueChange={value => {
                            setAdditionalData({...additionalData, 'ambientName' : value})
                        }}
                        name='ambientName' />

                    <Toggle 
                        label="Metragem dos cômodos"
                        value={additionalData.ambientSize}
                        onValueChange={value => {
                            setAdditionalData({...additionalData, 'ambientSize' : value})
                        }}
                        name='ambientSize' />
                </div>

                <div className="form-group">
                    <Toggle 
                        label="Mapa chave"
                        value={additionalData.keyMap}
                        onValueChange={value => {
                            setAdditionalData({...additionalData, 'keyMap' : value})
                        }}
                        name='keyMap' />

                    <Toggle 
                        label="Rosa dos ventos"
                        value={additionalData.windRose}
                        onValueChange={value => {
                            setAdditionalData({...additionalData, 'windRose' : value})
                        }}
                        name='windRose' />
                </div>
            </div>
        }

        {servicesDataForm.id === 'FILM_BASE_SECONDS' && serviceData.id && 
            <div className="sub-form">
                <div className="form-group">
                    <div className="form-field">
                        <label htmlFor="duration">Duração</label><Tooltip message="Em segundos" />
                        <input 
                            type="number" 
                            placeholder="60" 
                            id="duration"
                            name="duration"
                            value={additionalData.duration ? additionalData.duration : ''}
                            onChange={(e) => {
                                setAdditionalData({...additionalData, [e.target.name] : e.target.value})
                            }} 
                        />
                    </div>
                </div>

                <div className="form-group">
                    <Toggle 
                        label="Animação 2D"
                        value={
                            checkIfContainsService('FILM_ANIMATION_2D')
                        }
                        onValueChange={value => {
                            toggleChildService('FILM_ANIMATION_2D');
                        }}
                        name='animation2D' 
                        tooltip="Logomarca, transições, ícones, letreiros, gráficos, traços iluminados acompanhando a construção" />

                    <Toggle 
                        label="Animação 3D"
                        value={
                            checkIfContainsService('FILM_ANIMATION_3D')
                        }
                        onValueChange={value => {
                            toggleChildService('FILM_ANIMATION_3D');
                        }}
                        name='animation3D'
                        tooltip="Personagens caminhando, carros passando, árvores balançando, iluminações variando" />
                </div>

                <div className="form-group">
                    <Toggle 
                        label="Narração"
                        value={
                            checkIfContainsService('FILM_NARRATION')
                        }
                        onValueChange={value => {
                            toggleChildService('FILM_NARRATION');
                        }}
                        name='narration' />

                    {teleportType === "TELEPORT_COMMON_AREA" &&
                        <Toggle 
                            label="Video inserção"
                            value={
                                checkIfContainsService('FILM_INSERTION')
                            }
                            onValueChange={value => {
                                toggleChildService('FILM_INSERTION');
                            }}
                            name='videoInsert' />
                    }
                </div>

                {teleportType === "TELEPORT_COMMON_AREA" &&
                    <Toggle 
                            label="Filmagem aérea"
                            value={
                                checkIfContainsService('FILM_AERIAL')
                            }
                            onValueChange={value => {
                                toggleChildService('FILM_AERIAL');
                            }}
                            name='aerialFootage' />
                }
            </div>
        }

        {servicesDataForm.id === 'NEW_FEATURE' && serviceData.id &&
            <div className="sub-form">
                <div className="form-field">
                <label htmlFor="additionalProgrammingData">Descreva a experiência</label><Tooltip message="Descreva de maneira linear, do início ao fim, como imagina a experiência do usuário" />
                <textarea 
                    id="additionalProgrammingData" 
                    name="description"
                    value={additionalData.description ? additionalData.description : ''}
                    onChange={(e) => {
                        setAdditionalData({...additionalData, [e.target.name] : e.target.value})
                    }}></textarea>
                </div>
            </div>
        }

        {(servicesDataForm.id === 'VARIATION_LOCATED' || servicesDataForm.id === 'VARIATION_FULL') && serviceData.id &&
            <div className="sub-form">
                {ambients.length === 0 && <p>Nenhum ambiente selecionado no passeio</p>}
                {ambients.length > 0 && <h3>Selecione os ambientes com variação completa</h3>}
                {ambients.map(ambient => {
                    let checkedAmbient;

                    if(serviceData.ambients)
                        checkedAmbient = serviceData.ambients.find(item => item.ambient.id === ambient.id);

                    return (
                        <Toggle 
                            key={ambient.id}
                            label={ambient.value}
                            value={checkedAmbient ? true : false}
                            onValueChange={value => {toggleAmbientService(ambient)}}
                        />
                    )
                })}

                {ambients.length > 0 && 
                    <>
                        <div className="form-field">
                        <label htmlFor="teleportFullVariationDescription">Descreva as variações</label>
                        <textarea 
                            id="teleportFullVariationDescription" 
                            name="description"
                            value={additionalData.description ? additionalData.description : ''}
                            onChange={(e) => {
                                setAdditionalData({...additionalData, [e.target.name] : e.target.value})
                            }}></textarea>
                        </div>
                    </>
                }
            </div>
        }
        </>
    )
}

export default ServiceInfo;