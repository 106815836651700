import React, { useCallback, useEffect, useState } from 'react';
import BackendServiceV2 from 'services/v2/BackendService';
import Pagination from '../../../components/Pagination';
import PricingBox from '../../../components/PricingBox';
import SectionBox from '../../../components/SectionBox';

function PricingList({ location, history}) {

    // PRICING REQUEST
    const [isLoading, setIsLoading] = useState(true);

    const [pricingRequests, setPricingRequests] = useState([]);
    const [totalPages, setTotalPages] = useState(undefined);
    
    const getPricingRequests = useCallback(async (page) => {
        if(page !== undefined) {
            let response = await BackendServiceV2.get('pricing/request?page=' + page);

            if(response.status === 200) {
                setPricingRequests(response.data.result);
                setTotalPages(response.data.totalPages);
                setIsLoading(false);
            }
        }
    }, []);

    useEffect(() => {
        getPricingRequests();
    }, [getPricingRequests]);

    return (
        <div className="main-content">
            <div className="left-content">
                <SectionBox title="Pricing Requests">
                    {!isLoading && pricingRequests.map(pricingRequest => (
                        <PricingBox key={pricingRequest.id} pricingRequest={pricingRequest} />
                    ))}

                    {isLoading &&
                        <div className="loading-data">
                            <img src={require('../../../assets/loading.gif')} alt="Loading" />
                        </div>
                    }

                    <Pagination location={location} totalPages={totalPages} history={history} onPageChange={getPricingRequests} pathname={"/pricing"} />
                </SectionBox>
            </div>  

            <div className="right-content">
                
            </div>           
        </div>
    )
}

export default PricingList;