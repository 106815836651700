import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import dropdownIcon from '../../../assets/select_dropdown_icon.png';
import dropdownIconHover from '../../../assets/select_dropdown_icon_hover.png';
import Tooltip from '../Tooltip';

function Select({value, onValueChange, name, options, label, tooltip, headerMenu, clearable, searchable, error, disabled}) {

    const [filteredOptions, setFilteredOptions] = useState([]);
    const [isMouseOver, setIsMouseOver] = useState(false);

    const formField = useRef(undefined);
    const optionsUListBlock = useRef(undefined);
    const inputSearch = useRef(undefined);

    useEffect(() => {
        setFilteredOptions([...options]);
    }, [options])

    // Display options and Focus on Text Input
    async function onInputClick(e) {
        e.preventDefault();

        displayOptionsUListBlock();
        focusInputSearch();

        formField.current.addEventListener('mouseleave', (e) => {
            optionsUListBlock.current.style.display = 'none';
        });
    }

    // Search options when Text Input change
    async function onInputChange(e) {
        e.preventDefault();

        if(inputSearch === undefined || inputSearch.current === undefined || inputSearch.current === null)
            return;

        let searchString = inputSearch.current.innerHTML;

        setFilteredOptions(options.filter((option) => {
            if(option && option.value) 
                return option.value.toLowerCase().includes(searchString.toLowerCase().replace('&nbsp;', ' '));

            return false;
        }))
    }

    // Change selected value on option click
    async function onOptionSelected(option) {
        if(inputSearch === undefined || inputSearch.current === undefined || inputSearch.current === null)
            return;

        if(optionsUListBlock === undefined || optionsUListBlock.current === undefined || optionsUListBlock.current === null)
            return;

        optionsUListBlock.current.style.display = 'none';

        if(option) {
            inputSearch.current.textContent = option.value;

            onValueChange(option);
        } else {
            inputSearch.current.textContent = '';

            onValueChange(undefined);
        }
    }

    // Update UI and events behavior when value change
    useEffect(() => {
        if(value && value[name] !== undefined) {
            inputSearch.current.textContent = value[name].value;
        } else {
            inputSearch.current.textContent = '';
        }

        formField.current.addEventListener('mouseleave', (e) => {
            if(value && value[name] !== undefined) {
                inputSearch.current.textContent = value[name].value;
            } else {
                inputSearch.current.textContent = '';
            }

            setFilteredOptions([...options]);
        });
    }, [value, name, onValueChange, options])

    async function displayOptionsUListBlock() {
        if(optionsUListBlock === undefined || optionsUListBlock.current === undefined || optionsUListBlock.current === null)
            return;

        optionsUListBlock.current.style.display = 'block';
    }

    async function toggleOptionsUListBlock(e) {
        e.preventDefault();
        e.stopPropagation();

        if(optionsUListBlock === undefined || optionsUListBlock.current === undefined || optionsUListBlock.current === null)
            return;

        if(optionsUListBlock.current.style.display === 'block') {
            resetInput();
        } else {
            displayOptionsUListBlock();
        }
    }

    async function focusInputSearch() {
        if(inputSearch === undefined || inputSearch.current === undefined || inputSearch.current === null)
            return;

        inputSearch.current.focus();
    }

    async function resetInput() {
        optionsUListBlock.current.style.display = 'none';

        if(value !== undefined && value[name] !== undefined) {
            inputSearch.current.textContent = value[name].value;
        } else {
            inputSearch.current.textContent = '';
        }

        setFilteredOptions([...options]);
    }

    return (
        <div className={(disabled ? 'disabled ' : '') + 'form-input '} onMouseEnter={() => setIsMouseOver(true)} onMouseLeave={() => setIsMouseOver(false)}>
            <span className="form-input-header">
                <span>{label}</span>{tooltip && <Tooltip message={tooltip} />}
                {!disabled && headerMenu}
            </span>
            
            <div className="form-input-field" onClick={onInputClick} ref={formField}>
                <div className="border-top">{error && <span className="error">{error}</span>}</div>
                <div className="form-input-content">
                    {searchable && <span contentEditable className="form-input-value" ref={inputSearch} onInput={onInputChange} spellCheck="false"></span>}
                    {!searchable && <span className="form-input-value" ref={inputSearch} onInput={onInputChange} spellCheck="false"></span>}
                    
                    <span className="expander" onClick={toggleOptionsUListBlock}>
                        {!isMouseOver && <img src={dropdownIcon} alt="" />}
                        {isMouseOver && <img src={dropdownIconHover} alt="" />}
                    </span>
                </div>
                <div className="form-input-options">
                    <ul ref={optionsUListBlock}>
                        {clearable && <li data-value={undefined} onClick={(e) => {e.stopPropagation(); onOptionSelected(undefined)}}>Clear selection</li>}

                        {filteredOptions &&
                            filteredOptions.map(option => (
                                <li key={option.id} data-value={option.id} onClick={(e) => {e.stopPropagation(); onOptionSelected(option)}}>{option.value}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
            
        </div>
    )
}

export default Select;