import { SectionBox } from "components/v2/SectionBox";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { LoadingOverlay, SimpleGrid, Stack, Text } from "@mantine/core";
import { RenderFarmCard } from "components/v2/RenderFarm/RenderFarmCard";
import { RenderServiceScriptUpload } from "../RenderServiceScriptUpload";
import { UserContext } from "context/User/UserContext";

export function RenderServiceList() {

    const userContext = useContext(UserContext);

    const {data: virtualmachines, isLoading} = useQuery(['renderservice-virtualmachines-list-data'], () => {
        return BackendServiceV2.get('/virtualmachines/running');
    });

    return (
        <SectionBox title="Render Service" className="full-width">
            <LoadingOverlay visible={isLoading} />

            <Stack>
                <SimpleGrid cols={3}>
                    {virtualmachines?.map(vm => (
                        <RenderFarmCard key={vm.id} vm={vm} canChangeState={false} />
                    ))}
                </SimpleGrid>

                {virtualmachines && virtualmachines.length === 0 &&
                    <Text color="dimmed" size="xs" align="center">Nenhuma VM rodando.</Text>
                }

                {(userContext.loggedUserData.email === 'rodrigo.briet@iteleport.com.br' || userContext.loggedUserData.email === 'eduardo.alves@iteleport.com.br') &&
                    <RenderServiceScriptUpload />
                }
            </Stack>
        </SectionBox>
    )
}