import { BlobServiceClient } from "@azure/storage-blob";
import { Select, Stack, Text } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import UploadArea from "components/v2/UploadArea";
import React, { useRef } from "react";
import { useMutation } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";

export function RenderServiceScriptUpload() {

    const modals = useModals();
    const uploadArea = useRef(null);

    const form = useForm({
        initialValues: {
            type: ''
        },
        validate: {
            type: (value) => value === null || value === '' ? 'Selecione um script' : null,
        }
    });

    const uploadConfirmation = (values) => modals.openConfirmModal({
        title: 'Upload de script',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja modificar o script <b>{values.type}</b>?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: () => {
            generateUploadURL(values);
        },
        zIndex: 1000
    });
    
    const {mutate: generateUploadURL} = useMutation((values) => {
        uploadArea.current.validate();
        return BackendServiceV2.post('/renderservice/scripts', values)
    }, {
        onSuccess: (response) => {
            uploadArea.current.upload({
                mutate: async (files, config) => {
                    const blobServiceClient = new BlobServiceClient(window.ENV.StaticURL + response.signedURL);
                    
                    const containerClient = blobServiceClient.getContainerClient('main-storage');
                    const blockBlobClient = containerClient.getBlockBlobClient(response.entityDir + response.filename);

                    return blockBlobClient.uploadData(files[0], config);
                },
                success: () => {
                    form.reset();
                    uploadArea.current.reset();
                    showNotification({
                        title: 'Script atualizado com sucesso',
                        message: '',
                        icon: <Check />,
                        color: 'green',
                        autoClose: 5000,
                    });
                },
                error: (error) => {
                    uploadArea.current.reset();
                    showNotification({
                        title: 'Um erro ocorreu',
                        message: error?.response?.data?.message,
                        icon: <X />,
                        color: 'red',
                        autoClose: 5000,
                    });
                }
            });
        },
        onError: (error) => {
            uploadArea.current.reset();
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (
        <SectionBox title="Script Upload" className="full-width">
            <form>
                <Stack>
                    <Select
                        required
                        label="Script"
                        placeholder="Selecione um"
                        size="md"
                        data={[
                            {value: 'SKYBOXES_VALIDATION', label: 'SKYBOXES_VALIDATION'}, 
                            {value: 'SKYBOXES_PRODUCTION', label: 'SKYBOXES_PRODUCTION'}, 
                            {value: 'BAKE_PRODUCTION', label: 'BAKE_PRODUCTION'}, 
                            {value: 'VIDEO_PRODUCTION', label: 'VIDEO_PRODUCTION'}
                        ]}
                        {...form.getInputProps('type')}
                    />

                    <UploadArea
                        onDrop={async (files) => {
                            let validation = await form.validate();

                            if(validation.hasErrors) {
                                uploadArea.current.reset();
                            } else {
                                uploadConfirmation(form.values)
                            }
                        }}
                        rejectMessage="Erro"
                        maxSize={1024 * 5}
                        accept={[MIME_TYPES.zip, 'application/x-zip-compressed']}
                        multiple={false}
                        ref={uploadArea}
                    />
                </Stack>
            </form>
        </SectionBox>
    )
}