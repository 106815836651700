import React from 'react';
import { Link } from 'react-router-dom';

function PricingBox({ pricingRequest }) {

    return (
        <Link className="list-entry" to={"/pricing/" + pricingRequest.id}>
            <div className="list-content">
                <div className="list-header">
                    <span className="list-name">Pricing Request {pricingRequest.id}</span>
                </div>

                <div className="list-details">
                    <span>
                        <b>Status:</b> {pricingRequest.status}
                        <b>Company:</b> {pricingRequest.company.name}
                        <b>Development:</b> {pricingRequest.development.name}
                        <b>User:</b> {pricingRequest.user.email}
                    </span>
                </div>
            </div>
        </Link>
    )
}

export default PricingBox;